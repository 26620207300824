const _ = require('lodash')

const clearStateObject = (state) => {
	for (const n in state) {
		delete state[n]
	}
}

export  const stopSound = ( {state}, sound ) => {
	state.app.playing[sound.id] = false
}

export const popSound = ( {state}) => {
	state.app.soundQueue.shift()
}

