import React, {useState, useMemo, useEffect, useCallback} from "react"


import {dataUltraCrm} from "./data/ultra-crm";
import {dataAi} from "./data/ia";
import {dataCacheback} from "./data/cashback";
import {dataCampanhasPromocionais} from "./data/campanhas-promocionais";
import {dataAnaliseDadosBI} from "./data/analise-de-dados-e-bi";
import {dataSocialListening} from "./data/social-listening";
import {dataECommerce} from "./data/e-commerce";
import {dataHotsitePromocional} from "./data/hotsite-promocial";

export const list = () => {
	return [
		dataUltraCrm,
		dataAi,
		dataCacheback,
		dataCampanhasPromocionais,
		dataAnaliseDadosBI,
		dataSocialListening,
		dataECommerce,
		dataHotsitePromocional,
	]
};

export const get = (id) => {
	return list().find(el => el.id===id)
};
