import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/clients/santomercado.png"

export const dataClientsSantoMercado =
	{
		id : "santomercado",
		name : `Santo\nMercado`,
		challenge : `Modernizar a experiência promocional do Santo Mercado, mantendo a tradição do espaço, engajar visitantes e incentivar compras com prêmios atrativos como cashback, vouchers e experiências VIP.`,
		image : IMG,
		solution : `Confira os resultados das nossas campanhas e veja como transformamos ideias em ações que geram engajamento e impacto real.`,
		solutionItems : [
			{
				description : `Desenvolvimento de um hotsite interativo para gerenciar a mecânica promocional`,
			},
			{
				description : `Conexão de participantes à campanha de forma intuitiva e a implementação de um CRM personalizado`,
			},
			{
				description : `Otimização da comunicação e dos resultados da ação`,
			},
		],
		results : `Recursos captados para apoiar o combate à fome, alto engajamento do público, motivado por um prêmio surpreendente e reforço do compromisso com ações que unem impacto social e criatividade.`,
		website : `https://santomercadosp.com.br/`,
	}



