import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/services/analise-de-dados-e-bi.jpg"
import SC from "../../../assets/images/static/services/sucess-cases/analise-de-dados-e-bi.png"

export const dataAnaliseDadosBI =
	{
		id : "analise-de-dados-e-bi",
		mainTitle : `Análise de\nDados e BI`,
		mainDescription : `Decisões Inteligentes Baseadas em Dados. Transforme informações em estratégias com relatórios precisos e dashboards personalizados.`,
		title : `Decisões\nEstratégicas`,
		description : <>A Dompa utiliza tecnologia avançada para <strong>transformar dados brutos em relatórios precisos e insights estratégicos.</strong>
			<br/>Oferecemos <strong>dashboards interativos</strong> e personalizados, permitindo que empresas acompanhem campanhas e resultados de forma clara e eficiente.</>,
		image : IMG,
		functionsSubTitle : `Como Nossa Solução Pode Impulsionar Seu Negócio?`,
		functions : [
			{
				title : `Relatórios Precisos`,
				description : `Dados organizados e detalhados para decisões informadas`,
			},
			{
				title : `Dashboards Personalizados`,
				description : `Painéis adaptados às necessidades específicas da empresa, com informações em tempo real.`,
			},
			{
				title : `Insights Estratégicos`,
				description : `Identificação de padrões e oportunidades para potencializar resultados.`,
			},
			{
				title : `Monitoramento de Campanhas`,
				description : `Acompanhe o desempenho de campanhas promocionais com métricas claras.`,
			},
			{
				title : `Integração de Dados`,
				description : `Conexão com diferentes fontes para análises centralizadas.`,
			},
		],
		benefitsSubTitle : `Por Que Escolher Nossa Solução de BI?`,
		benefits : [
			{
				title : `Decisões Baseadas\nem Dados`,
				description : `Minimize incertezas com informações precisas`,
			},
			{
				title : `Visão\nHolística`,
				description : `Consolide dados de diferentes fontes\nem uma única plataforma`,
			},
			{
				title : `Eficiência\nOperacional`,
				description : `Acelere análises com relatórios\nautomatizados e dinâmicos`,
			},
			{
				title : `Personalização\nCompleta`,
				description : `Dashboards que atendem às demandas\nespecíficas do seu negócio`,
			},
			{
				title : `Resultados\nMensuráveis`,
				description : `Métricas claras que auxiliam no\nacompanhamento de KPIs`,
			},
		],
		applicationDescription:`Como o BI Está Transformando Empresas?`,
		applications : [
			{
				title : `Monitoramento de Campanhas Promocionais`,
				description : `Relatórios detalhados sobre participação e engajamento.`,
			},
			{
				title : `Análise de Mercado`,
				description : `Identificação de tendências e oportunidades estratégicas`,
			},
			{
				title : `Gestão de Vendas`,
				description : `Dashboards que acompanham o desempenho de produtos e metas.`,
			},
			{
				title : `Medição de ROI`,
				description : `Avaliação clara do retorno sobre investimentos em campanhas`,
			},
		],
		successCase : {
			name : `Santo Mercado`,
			title : `BI em Ação`,
			image : SC,
			solution : `Criação de dashboards\npersonalizados para acompanhar métricas de\nengajamento e vendas`,
			challenge : `Monitorar a eficiência\nde campanhas promocionais e\ngerar insights para estratégias\nfuturas.`,
			percent : 20,
			percentExplanation : `Aumento de vendas`,
		},
	}



