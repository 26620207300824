import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/products/vollu.png"
import KIMG from "../../../assets/images/static/products/knowmore/vollu.jpg"

export const dataProductsVollu =
	{
		id : "vollu",
		name : "Vollu",
		title : "Autoconhecimento e inovação se encontram para te premiar",
		description : `O Vollu é uma plataforma única que combina gamificação com autoconhecimento, oferecendo experiências interativas e recompensadoras. Ideal tanto para pessoas que buscam se conhecer melhor quanto para empresas que desejam transformar treinamentos, campanhas internas e ações de engajamento em iniciativas dinâmicas e impactantes.`,
		image : IMG,
		users : "11.456",
		influencers : "16",

		mainDescription : `O Vollu é uma plataforma única que combina gamificação com autoconhecimento, oferecendo experiências interativas e recompensadoras. Ideal tanto para pessoas que buscam se conhecer melhor quanto para empresas que desejam transformar treinamentos, campanhas internas e ações de engajamento em iniciativas dinâmicas e impactantes.`,

		partnerType : `Perfis`,

		knowMoreDescription : `O Vollu é uma plataforma única que combina gamificação com autoconhecimento, oferecendo experiências interativas e recompensadoras. Ideal tanto para pessoas que buscam se conhecer melhor quanto para empresas que desejam transformar treinamentos, campanhas internas e ações de engajamento em iniciativas dinâmicas e impactantes.`,

		knowMoreImage2 : KIMG,
		knowMoreTitle2 : `Autoconhecimento e Inovação`,
		knowMoreDescription2 : `Com testes personalizados e incentivos atrativos, o Vollu cria um ambiente mais motivador, estimulando o desenvolvimento pessoal e profissional de maneira inovadora e divertida.`,

		knowMoreInfoDescription : `O primeiro guia personalizado de autoconhecimento que combina entretenimento e desenvolvimento pessoal, oferecendo aos usuários uma experiência única e transformadora.`,
		website : `https://vollu.com.br/`,
	}
