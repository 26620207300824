import React, {useState, useMemo, useEffect, useCallback} from "react"

// import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
// import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import "./service-functions.scss"
import {ReactComponent as Func1} from "../../../../assets/images/static/services/icons/func1.svg"
import {ReactComponent as Func2} from "../../../../assets/images/static/services/icons/func2.svg"
import {ReactComponent as Func3} from "../../../../assets/images/static/services/icons/func3.svg"
import {ReactComponent as Func4} from "../../../../assets/images/static/services/icons/func4.svg"
import {ReactComponent as Func5} from "../../../../assets/images/static/services/icons/func5.svg"

import nl2br from "react-nl2br";
import Circles from "../../../components/blocks/Circles";
import {ReactComponent as CirclesImage} from "../../../../assets/images/circles-5.svg";

const IconFunctions = [
	Func1,
	Func2,
	Func3,
	Func4,
	Func5,
]


const Card = (
	{
		Icon,
		title,
		description,
		command,
		image,
		descriptionPaddingRight = "0rem",
		actions,
	}
) => {
	if (!title && !description)
		return null

	return (
		<div
			className="service-functions-card d-flex flex-column position-relative debug5"

		>
			{Icon && <div className="card-icon flex-grow-0 debug1">
				<Icon width={44} height={45} />
			</div>
			}
			<div className="card-title flex-grow-0 debug2">{nl2br(title)}</div>
			<div className="card-description flex-grow-0 debug2" style={{paddingRight:descriptionPaddingRight}} >{nl2br(description)}</div>
		</div>
	)
}

const ServiceFunctions = (
	{
		data,
		actions,
	}
) => {

	const cards = data.functions

	return <div className="service-functions debug1 d-flex flex-row justify-content-center position-relative">
		<div className="d-flex flex-column block-content ">

			{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
			{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

			<div className="d-flex flex-column content z-2">
				<div className="flex-grow-0 debug2 line-top"/>
				<div className="flex-grow-0 debug2 title font-tt">
					Funcionalidades
				</div>
				<div className="flex-grow-0 debug2 sub-title">
					{data.functionsSubTitle}
				</div>
				<div className="cards flex-grow-1 flex-fill z-3 debug4 position-relative" >
					<Circles
						className="zx-1 opacity-25"
						Image={CirclesImage}
						width={840}
						height={840}
						left={840/2 - 120}
						top={50}
					/>

					{cards && cards.map((card, index) =>
						<Card key={index} {...card} Icon={IconFunctions[index]} />
					)}
				</div>
				<div className="flex-grow-0 debug2 line-bottom"/>
			</div>


		</div>
	</div>
}




export default ServiceFunctions;
