import moment from "moment"
import {isCpfFormat} from "./parser_helper";
moment.locale('pt-BR')

export const onlyNumbers = (value) => {
	if (typeof value == 'number' && value)
		return value.toString()

	return value ? value.replace(/[^\d]+/g,'') : ''
}

export const booleanValue = (value) => {
	return (value==true || value=='true' || value=='1')  ? '1' : '0'
}

export const optionValue = (value) => {
	return value!==undefined && value!==null && value ? value.toString() : ''
}

export const formatDocument = (document) => {
	try {
		return isCpfFormat(document)
				? formatCpf(document)
				: formatCnpj(document)
	} catch(e) {
		return document
	}
}

export const formatCpf = (cpf) => {
	try {
		return cpf
			.replace(/[^\d]/g, "")
			.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
	} catch(e) {
		return cpf
	}
}

export const formatCnpj = (cnpj) => {
	try {
    return cnpj
		.replace(/[^\d]/g, "")
		.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
	} catch(e) {
		return cnpj
	}
}

// export const dateISOToBr = (strDatetime, skipTime = false) => {
// 	if (!strDatetime) return '';
// 	const datetime = strDatetime.split(' ');
// 	const date = datetime[0].split('-');
// 	const time =
// 		datetime[1] === '00:00:00' || skipTime ? null : datetime[1].split(':');
//
// 	let ret = `${date[2]}/${date[1]}/${date[0]}`;
//
// 	if (time) ret += ` ${time[0]}:${time[1]}`;
//
// 	return ret;
// };

export const dateTimestampToBr = (timestamp, skipTime = true) => {
	//console.log('dateTimestampToBr', {timestamp, skipTime})
	if (!timestamp) return '';

	try {
		return moment(timestamp).format('DD/MM/YYYY')
	} catch(e) {
		return ''
	}

	// const date = new Date(timestamp);
	// let day = `${date.getDate()}`;
	// let month = `${date.getMonth() + 1}`;
	// const year = `${date.getFullYear()}`;
	// if (day.length < 2) day = `0${day}`;
	// if (month.length < 2) month = `0${month}`;
	//
	// const str = `${day}/${month}/${year}`;
	// // if (str=='01/01/1970')
	//
	// return str;
};


export const PercentFormat = (value, sufix='', decimals=2) => {
	const pValue = MoneyFormat(value, '', decimals)
	// console.log('PercentFormat', `${pValue}${sufix}`.replace('.', ','))
	return `${pValue}${sufix}`.replace('.', ',')
}

export const MoneyFormat = (value, prefix='', decimals=2) => {
	try {
		if (!value || value === undefined) value = 0;
		const startValue = value
		//console.log("MoneyFormat", value)
		const result = Number(value)
			.toFixed(decimals)
			.toString()
			.replace('.', ',')
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		//console.log("MoneyFormatResult", result)
		// console.log("MoneyFormatResult", {startValue, result, value:prefix+result.toString()})
		return prefix+result.toString()
	} catch (e) {
		 console.log('MoneyFormat error: ', {e, value})
		return prefix ? prefix + '0,0' : 0.0;
	}
};

export const NumberFormat = (value) => {
	try {
		if (!value || value === undefined) value = 0;
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
	} catch (e) {
		return "0"
	}
};

export const dateTimestampToISO = (timestamp, skipTime = true) => {
	const date = new Date(timestamp);
	let day = `${date.getDate()}`;
	let month = `${date.getMonth() + 1}`;
	const year = `${date.getFullYear()}`;
	if (day.length < 2) day = `0${day}`;
	if (month.length < 2) month = `0${month}`;

	return `${year}-${month}-${day}`;
};

export const dateBrToISO = (value) => {
	//let dateObj = new Date(value)
	let MD = moment(value, 'DD/MM/YYYY', true)
	return MD.isValid()
		? MD.format('YYYY-MM-DD')
		: undefined

	// let dateString = dateObj.toLocaleString('en-US', {
	// 	year: 'numeric',
	// 	month: '2-digit',
	// 	day: '2-digit',
	// 	//hour: '2-digit',
	// 	//minute:'2-digit',
	// 	//second:'2-digit'
	// }).replace(/\//g, '-')
	// console.log({dateString, value})
	// return dateString
};

export const formatPersonName = (name) => {
	if (!name) return '';
	try {
		const firstName = name?.trim()?.split(' ')[0].toUpperCase()
		return firstName[0].toUpperCase() + firstName.substr(1, name.length).toLowerCase()
	} catch(e) {
		return name
	}

}

export const dateIsoToBr = (value, withTime=false, format=null) => {
	// console.log('dateIsoToBr', {value, withTime, format})
	//let dateObj = new Date(value)
	let MD = moment.utc(value, 'YYYY-MM-DD HH:mm:ss')
	return MD.isValid()
		? MD.format(
			format ? format : (
				withTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY'
			)
		)
		: undefined
}

export const dateIsoToBrFriendly = (value, withTime=false, format=null) => {
	// console.log('dateIsoToBr', {value, withTime, format})
	//let dateObj = new Date(value)

	if (!value)
		return null

	let MD = moment(value)
	if (!MD.isValid())
		return null


	const secondsPassed = moment().diff(MD, 'seconds');

	if (secondsPassed<10)
		return "Agora mesmo"
	if (secondsPassed<60)
		return "Agora pouco"
	if (secondsPassed<60*2)
		return "Há um minuto"
	if (secondsPassed<60*3)
		return "Há dois minutos"
	if (secondsPassed<60*5)
		return "Menos de 5 minutos"
	if (secondsPassed<60*24)
		return MD.format(`HH:mm`)
	else
		return MD.format(
			format ? format : (
				withTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY'
			)
		)

	// console.log({secondsPassed})
	//
	// return MD.isValid()
	// 	? MD.format(
	// 		format ? format : (
	// 			withTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY'
	// 		)
	// 	)
	// 	: undefined
}

export const dateBrToDateObject = (value) => {
	return new Date(
		`${value.substr(3, 2)}/${value.substr(0, 2)}/${value.substr(6, 24)}`
	).toLocaleDateString()
}

export const clearSpaces = (str, allowNumbers=false) => {
	const reg = allowNumbers
		? /[^a-zA-Z0-9]/g
		: /[^a-zA-Z]/g

	return str.replace(reg, "").trim()
}

export const clearPonctuation = (str, allowNumbers=false) => {

	if (!str) return '';

	const reg = allowNumbers
		? /[^a-zA-Z0-9 ]/g
		: /[^a-zA-Z ]/g

	//str = str.replace(/[^a-zA-Z ]/g, "")
	//console.log('str', str)

	if (!str.normalize)
		return str.replace(reg, " ");

	const normal = str.normalize('NFD');

	if (!normal)
		return str.replace(reg, " ");

	return str.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(reg, " ");
};

export const ucFirst = (string="") => {
	if (!string?.length)
		return ""
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const firstName = (name, inNameCase=true) => {
	let fName = name
	try {
	  fName = fName.trim().split(' ')[0]
		if (inNameCase)
		  fName = fName.charAt(0).toUpperCase() + fName.slice(1).toLowerCase();
	} catch(e) {
		fName = name
	}
	return fName
}

export const cancelEvent = e => {
	e.preventDefault()
	e.stopPropagation()
	e.cancelBubble = true
}

export const cancelEventPropagation = e => {
//	e.preventDefault()
	e.stopPropagation()
	e.cancelBubble = true
}


export const dialogAddCloseOnClickOutside = (callback) => {
	const element = document.querySelector('.p-dialog-mask')
	element && element.addEventListener('click', e => {
		const el = e.target
		if (el.classList.contains('p-dialog-mask'))
			callback()
	})
}

export const getFileMimeType = (data) => {
	let mimeType

	if (typeof data === 'object') {
		mimeType = 'json'
	} else if (typeof data === 'string') {
		if (data.toString().substr(0, 5)==='data:') {
            const parts = data.split(';')
            mimeType = parts[0].split('/')[0]
			if (parts[0].split('/')[1]==="pdf")
				mimeType = "pdf"
		} else {
			mimeType = data.substr(data.lastIndexOf('.')+1, data.length)
			if (['webp', 'png', 'gif', 'bmp', 'svg', 'jpg', 'jpeg'].includes(mimeType.toLowerCase()))
				mimeType = 'image'
			else if (['json'].includes(mimeType.toLowerCase()))
				mimeType = 'json'
		}
	}
	return mimeType
}

export const processUpload = (data, name) => {
	if (!data[name]) {
		// console.log('!data[name]')
		return
	}

	if (data[name]==='[deleted]') {
		// console.log('[deleted]')
		return
	}

	if (typeof data[name] === 'object') {
		data[name] = JSON.stringify(data[name])
	} else if (typeof data[name] === 'string') {
		if (data[name].toString().substr(0, 5)==='data:') {
			// console.log('data:')
			return
		} else {
			delete data[name]
			// console.log('DELETED IMAGE UPLOAD ' + name)
		}
	} else alert('processUpload: type not known')

}

export const changedFormUploadFile = fileUpload => {
	// console.log('fileUpload', fileUpload)
	if (typeof fileUpload === 'object')
		return true
	if (fileUpload && fileUpload.toString().substr(0, 4)==='http')
		return false

	return fileUpload && fileUpload.toString().substr(0, 5)==='data:'
}

export const loadRewardsData = (rewards, groups=['rewardResumes', 'rewardTests', 'rewardJourneys']) => {
	// console.log({rewards})
	let {rewardResumes=[], rewardTests=[], rewardJourneys=[]} = rewards || {}
	//rewardTests = rewardTests.map(data => {return {...data, value:data.id}})
	const result = {rewardResumes, rewardTests, rewardJourneys}
	// console.log({result})
	return result
}

export const processValuesRewards = (values, groups=['rewardResumes', 'rewardTests', 'rewardJourneys']) => {
	const rewards = {}
	let hasReward = false
	for (const group of groups) {
		if (values[group]) {
			const result = Object
				.values(values[group])
				.filter(value => !value?.tempId)
				.map(value => value?.id || value)
				.filter(value => !!value)
			if (result.length) {
				rewards[group] = result
				hasReward = true
			}
		}
		delete values[group]
	}

	return hasReward ? rewards : null
}

export const removeEmptyValues = (obj) => {
	if (Array.isArray(obj)) {
		// For arrays, recursively clean each element and filter out empty values
		return obj
			.map(item => removeEmptyValues(item)) // Recursively clean array elements
			.filter(item => item !== null && item !== undefined && item !== ''); // Filter out empty values
	} else if (typeof obj === 'object' && obj !== null) {
		// For objects, clean nested objects recursively
		return Object.fromEntries(
			Object.entries(obj)
				.filter(([_, value]) => value !== null && value !== undefined && value !== '')
				.map(([key, value]) => [
					key,
					// Recursively clean for both objects and arrays
					typeof value === 'object' ? removeEmptyValues(value) : value
				])
		);
	}
	// Return the value as-is if it's neither an object nor an array
	return obj;
};

export const extractObjectItems = (originalJson, filterFn) => {
	const extracted = [];
	const remaining = JSON.parse(JSON.stringify(originalJson)); // Deep clone the original JSON

	function traverseAndFilter(obj) {
		if (Array.isArray(obj)) {
			for (let i = obj.length - 1; i >= 0; i--) {
				if (filterFn(obj[i])) {
					extracted.push(obj[i]);
					obj.splice(i, 1); // Remove the item from the array
				} else {
					traverseAndFilter(obj[i]);
				}
			}
		} else if (typeof obj === "object" && obj !== null) {
			for (const key in obj) {
				if (Array.isArray(obj[key])) {
					traverseAndFilter(obj[key]);
				} else if (typeof obj[key] === "object") {
					traverseAndFilter(obj[key]);
				} else {
					obj.nope1 = true
				}
			}
		} else {
			obj.nope2 = true
		}
	}

	traverseAndFilter(remaining);

	return { extracted, remaining };
}

