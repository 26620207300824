import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as Circles} from "../../assets/images/circles.svg"
import BackgroundImage from "../../assets/images/backgrounds/services.jpg"
import {ReactComponent as Pointer} from "../../assets/images/icons/pointer.svg"
import Hero from "../components/blocks/Hero/Hero";
import Button from "../components/layout/Button/Button";
import Page from "../components/layout/Page/Page";

import services from "../../services";
import ServiceDescription from "./blocks/ServiceDescription/ServiceDescription";
import ServiceFunctions from "./blocks/ServiceFunctions/ServiceFunctions";
import ServiceBenefits from "./blocks/ServiceBenefits/ServiceBenefits";
import ServiceApplications from "./blocks/ServiceApplications/ServiceApplications";
import ServiceSuccessCases from "./blocks/ServiceSucessCases/ServiceSuccessCases";
import nl2br from "react-nl2br";


const ServicesPage = (
	{
		...props
	}
) => {

	const params = useParams()
	const serviceId = useMemo(() => params.serviceId, [params])
	const data = useMemo(() => services.services.get(serviceId || 'ultra-crm'), [serviceId])

	const navigate = useNavigate()

	if (!data)
		return null

	return (

		<Page>
			<Hero
				preTitle={<div className="text-28 fw-bold">CONHEÇA NOSSOS SERVIÇOS</div>}
				title={<div className="text-128 fw-bold font-tt">{nl2br(data.mainTitle)}</div>}
				description={<div className="text-28">{nl2br(data.mainDescription)}</div>}

				BackgroundImage={BackgroundImage}
				backgroundPositionY="-8.5rem"
				backgroundPaddingBottom="8rem"

				paddingTop={"15rem"}
				circleTop={"33rem"}

				// CirclesImage={Circles}
				circleRotate={0}
				circleRotateX={0}
				circleRotateY={0}
				circleTranslateX={"-260px"}
				circleTranslateY={"-300px"}

				actions={<>
					<Button label="Contato" size={"big"} onClick={() => navigate(`/#contato`)}/>
					<Button label="Nossos Clientes" onClick={() => navigate(`/clientes`)} template={"link"} size={"big"} IconRight={Pointer}/>
				</>}
				// circleTranslateX={"-100px"}
			/>

			<ServiceDescription data={data}/>
			<ServiceFunctions data={data}/>
			<ServiceBenefits data={data}/>
			<ServiceApplications data={data}/>
			<ServiceSuccessCases data={data}/>
			{/*<OurMissionVision/>*/}
			{/*<OurValues/>*/}
			{/*<OurTeam/>*/}

			{/*<TalkToUs />*/}
		</Page>
	)
}

export default ServicesPage;
