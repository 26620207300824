import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./products-know-more.scss"
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
import {ReactComponent as CirclesImage4} from "../../../../assets/images/circles-4.svg"
import {ReactComponent as ArrowLeft} from "../../../../assets/images/icons/arrow-left.svg"
import {ReactComponent as Sphere} from "../../../../assets/images/icons/sphere.svg"
import {ReactComponent as IconUsers} from "../../../../assets/images/icons/users.svg"
import {ReactComponent as IconInfluencers} from "../../../../assets/images/icons/influencers.svg"
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import services from "../../../../services";
import { useLocation, useNavigate, useParams } from "react-router-dom";


import nl2br from "react-nl2br"
import Circles from "../../../components/blocks/Circles";
import Button from "../../../components/layout/Button/Button";

const ProductsKnowMore = (
	{
		data,
		...props
	}
) => {

	const navigate = useNavigate()

	const selectedProduct = data

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative">


			<div className="products-know-more d-flex flex-column block-content position-relative">

				<div className="d-flex debug1" >
					<div className="image-container flex-grow-0 position-relative">
						<Circles
							Image={CirclesImage4}
							rotateX={0}
							rotateY={0}
							rotate={40-45}
							width={380}
							height={380}
							top={70}
							left={45}
						/>
						<div className="position-absolute image-box debug3" >
							<div className="w-100 h-100 image" style={{backgroundImage:`url(${selectedProduct.image})`}} />
						</div>

					</div>
					<div className="info-container flex-grow-1 flex-fill">
						<div className="product-title font-tt">Saiba Mais</div>
						<div className="product-description ">{selectedProduct.knowMoreDescription}</div>
						<div className="counter-container d-flex flex-row debug1 ">
							<div className="counter-user-container debug2">

								<div className="counter-user d-flex flex-column gap-3">
									<div className="text-60 fw-bold font-tt">
										{selectedProduct.users}
									</div>
									<div className="d-flex flex-row align-items-center">
										<div className="pe-2"><IconUsers width={32} height={32}/></div>
										<div className="text-18 p-2">Usuários</div>
									</div>
								</div>

							</div>

							<div className="counter-line">

							</div>

							<div className="counter-user-container">

								<div className="counter-user d-flex flex-column gap-3">
									<div className="text-60 fw-bold font-tt">
										{selectedProduct.influencers}
									</div>
									<div className="d-flex flex-row align-items-center">
										<div className="pe-2"><IconInfluencers width={32} height={32}/></div>
										<div className="text-18 p-2">{selectedProduct.partnerType}</div>
									</div>
								</div>


							</div>
						</div>
					</div>
				</div>

			</div>


		</div>
	)
}




export default ProductsKnowMore;
