import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/Home/Home";
import WhoWeArePage from "../pages/WhoWeAre/WhoWeAre";
import ServicesPage from "../pages/Services/Services";
import ClientsPage from "../pages/Clients/Clients";
import ProductsPage from "../pages/Products/Products";

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/quemsomos" Component={WhoWeArePage}/>

			<Route path="/servicos" Component={ServicesPage}/>
			<Route path="/servicos/:serviceId" Component={ServicesPage}/>

			<Route path="/clientes" Component={ClientsPage}/>
			<Route path="/clientes/:clientId" Component={ClientsPage}/>

			<Route path="/produtos" Component={ProductsPage}/>
			<Route path="/produtos/:productId" Component={ProductsPage}/>
			<Route path="/*" Component={HomePage} />
		</Routes>
	);
};

export default AppRoutes;
