export let AppCache = {};

export const resetAppCache = () => {
};

export const setGotoUrl = (url) => {
  if (!url) localStorage.removeItem("goto-url");
  else localStorage.setItem("goto-url", url);
};

export const getGotoUrl = () => {
  return localStorage.getItem("goto-url");
};

resetAppCache();
