import React, {useEffect, useState, useCallback, useMemo} from "react"

// import { AvField } from "availity-reactstrap-validation"
// import { useOvermind } from "../../../../overmind";

import { loadOptions } from "../../../helpers/constants/remoteLists";
import Select, { components } from "react-select"
import CreatableSelect from "react-select/creatable"

import {customStyles} from "./customStyles";
import MultiImage from "../../Layout/MultiImage";

// import {Device, isRNWebView} from "../../../configuration";
// import {getFieldInputElement, setFocusOnSelector, setFocusOnSelectorByField} from "../CustomForm/Utils/DomProp";

const { Option, MultiValue, ClearIndicator, SingleValue } = components;
const { ValueContainer, Placeholder, MultiValueRemove } = components;


const CustomClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <ClearIndicator
      {...props}
      ref={ref}
      // style={getStyles('clearIndicator', props)}
	  className="debug1 custom-clear-indicator"
    >
      <div className="label" style={{ padding: '0px 5px 0px 0px', position:'relative', marginTop:-15 }}>X</div>
    </ClearIndicator>
  );
};


const CustomOption = (props) => {
	// console.log({props})
	return (
		<Option {...props} className={`s2-multi-value-option ${props.data.image ? "scale-select2" : ""}`}>
		  <div className={`d-flex align-items-center `}>
			  {props.data.image && <>
				<div className="rounded rounded-1" style={{backgroundColor:props.data.color || undefined}}>
			  	<MultiImage
					src={typeof props.data.image === 'function' ? props.data.image(props.data) : props.data.image}
					width={22}
					height={22}
					className=" rounded rounded-1 select2-image-preview"
					style={{padding:"3px"}}
				/>
				</div>
			  </>}
			  {(props.data.iconClass && !props.data.image)  &&
			  	<i
					className={`s2-multi-value-icon ${props.data.type} ${props.data.iconClass}  me-1 `}
					style={{backgroundColor:props.data.color || undefined}}
				/>
			  }
			<div className="ps-1 select2-option-label">
				{(props.data.groupLabel && props.selectProps.showGroupLabels) ? `${props.data.groupLabel}: ${props.data.label}` : props.data.label}
			</div>
		  </div>
		</Option>
	)
}

const CustomSingleValue = (props) => {
	// console.log({props})
	return (
		<SingleValue {...props} className={`s2-value-option `} >
		  <div className={`d-flex align-items-center `}>
			  {props.data.image && <>
				<div className="rounded rounded-1" style={{backgroundColor:props.data.color || undefined}}>
					<MultiImage
						src={typeof props.data.image === 'function' ? props.data.image(props.data) : props.data.image}
						width={24}
						height={24}
						className={`select2-single-value-image-preview rounded rounded-1`}
						style={{padding:"3px"}}

					/>
				</div>
			  </>}
			  {(props.data.iconClass && !props.data.image) &&
			  	<i
					className={`s2-value-icon ${props.data.type} ${props.data.iconClass}  me-1 `}
					style={{backgroundColor:props.data.color || undefined}}
				/>
			  }
			<div className="ps-1 select2-option-label">
				{(props.data.groupLabel && props.selectProps.showGroupLabels) ? `${props.data.groupLabel}: ${props.data.label}` : props.data.label}
			</div>
		  </div>
		</SingleValue>
	)
}

// const CustomOption = ({ innerProps, isDisabled }) =>
//   !isDisabled ? (
//     <div {...innerProps}>
//
// 	</div>
//   ) : null;

const MultiValueOption = (props) => {
	return (
		<MultiValue {...props} className={`s2-multi-value-option group-default `} >
		  <div className="s2-multi-value-suboption d-flex align-items-center " title={props.data.description} >
			  {props.data.image && <>
				<div className="rounded rounded-1" style={{backgroundColor:props.data.color || undefined}}>
					<MultiImage
						src={typeof props.data.image === 'function' ? props.data.image(props.data) : props.data.image}
						width={20}
						height={20}
						className={`  rounded rounded-1 `}
						style={{padding:"0px"}}
					/>
				</div>
			  </>}
			  {(props.data.iconClass && !props.data.image) &&
			  	<i
					className={`s2-multi-value-icon ${props.data.type} ${props.data.iconClass}  me-1 `}
					style={{backgroundColor:props.data.color || undefined}}
				/>
			  }
			<div className="ps-1" >
			{(props.data.groupLabel && props.selectProps.showGroupLabels) ? `${props.data.groupLabel}: ${props.data.label}` : props.data.label}
			</div>
		  </div>
		</MultiValue>
	);
};

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
		  <div className="d-flex align-items-center">
			  {props.selectProps.placeHolderIconClass && <i className={`${props.selectProps.placeHolderIconClass} pe-1`} /> }

			<div className="pe-5">{props.selectProps.placeholder}</div>
		  </div>
      </Placeholder>
      {React.Children.map(children, child => {
			return child && child.type !== Placeholder ? child : null
		  }
      )}
    </ValueContainer>
  );
};

let focusTimeout, openTimeout
function CustomSelect(
	{
		noOptionsMessage = () => <div className="text-pink">(não encontrado)</div>,
		emptyOptionMessage,
		disabled,
		...props
	}
) {
	const {isMulti, isClearable=false, isCreatable, listId=''} = props
	const [options, setOptions] = useState(props.options || [])
	// const [mappedOptions, setMappedOptions] = useState([])
	const [loading, setLoading] = useState(listId ? true : false)
    const [value, setValue] = useState(props.value)
    const [mappedValue, setMappedValue] = useState(null)
    const [menuIsOpen, setMenuIsOpen] = useState(undefined)

	// console.log('value', value)
	// console.log('options', options)

    const handleKeyDown = e => {
		// if (e.keyCode===9) {
		// 	const selected = mappedOptions.find(el => el.value===value)
		// 	// console.log(`value=${value} value=${selected?.value}`)
		// 	// console.log(`selected`, selected)
		// 	handleChange(selected)
		// 	// alert(value)
		// 	e.preventDefault()
		// }
	}
    const handleFocus = e => {
		// if (Device.isMobile) {
		// 	// if (focusTimeout) clearTimeout(focusTimeout)
		// 	// focusTimeout = setTimeout(() => setFocusOnSelector(`form-control-${props.fieldId}`), 300)
		// 	// if (openTimeout) clearTimeout(openTimeout)
		// 	// focusTimeout = setTimeout(() => setMenuIsOpen(true), 700)
		// 	setMenuIsOpen(true)
		// } else {
		// 	setMenuIsOpen(true)
		// }
		props.onFocus && props.onFocus(e)
	}

    const handleBlur = e => {
		// if (focusTimeout) clearTimeout(focusTimeout)
		// if (openTimeout) clearTimeout(openTimeout)
		// setMenuIsOpen(false)
		// props.onBlur && props.onBlur(e)
	}

	const CustomMultiValueRemove = () => <div className="ps-2"/>;

    const handleCreateOption = inputValue => {
		// setTimeout(() => {
			const newOption = {id:inputValue, caption:inputValue, type:"custom", iconClass:"pi pi-file-edit", groupLabel:""};
			const newValue = {value:inputValue, label:inputValue, type:"custom", iconClass:"pi pi-file-edit", groupLabel:""};
			// setIsLoading(false);
			let newOptions
			setOptions((prev) => {
				newOptions = [...prev, newOption]
				return newOptions
			});
			// setValue((prev) => [...prev, inputValue]);
			// console.log('mappedValue', mappedValue)
			if (isMulti) {
				handleChange([...mappedValue||[], newValue], newOptions, 'yes')
				//setValue([...mappedValue||[], newValue])
			} else {
				handleChange(newValue)
				setValue(newOption.id);
			}
			// setMappedValue([...value, newValue]);
		// }, 1000);
	}

    const handleChange = (selected, newOptions, setNewOptions) => {
		const value = isMulti
			? selected.reduce(function(map, obj) {
				map.push(obj.value)
				return map;
			}, [])
			: selected?.value || ''

		//console.log('setValue', value)
        setValue(value)
		// setMenuIsOpen(false)
		// const input = document.getElementById(props.inputId)
		// if (input) {
		// 	// input.value = value
		// 	input.blur()
		// }
		// console.log({selected, value})
        if (props.onChange)
            props.onChange(isMulti ? selected : value, setNewOptions==='yes' ? newOptions : undefined)
		//
		// // if (props.isSearchable===false) {
		// // 	document.querySelector('.btn').focus()
		// // }
    }

	const getMappedOptions = (options) => {
    	// console.log('getMappedOptions', options)
		const s2Options = options && options.reduce(function(map, obj) {
			map.push({value:obj.id, label:obj.caption, icon:obj.icon, description:obj.description, type:obj.type, iconClass:obj.iconClass, image:obj.image, groupLabel:obj.groupLabel, color:obj.color})
			return map;
		}, isMulti || props.noEmptyOption ? [] : [{value:'', label:props.emptyOptionText||<b>{emptyOptionMessage || "(vazio)"}</b>}])

		// return [
		// 	{label:props.caption, options:s2Options},
		// ]
		// console.log('s2Options', s2Options)
		return s2Options
	}

	const mappedOptions = useMemo(() => getMappedOptions(options), [options])

	// useEffect(() => {
	// 	setMappedOptions(getMappedOptions(options))
	// }, [options])

	useEffect(() => {
		// setOptions([...options, ...props.options])
		setOptions(props.options)
	}, [props.options])

	useEffect(() => {
		// if (!value || !mappedOptions)
		// 	return
		//if (props.name=='address.state') alert(value)
		// console.log('fired value', {name:props.name, value})

		if (value) {
			// console.log({value, mappedOptions})
			const values = isMulti
				? value?.reduce(function(map, objValue) { //reducer to keep added order
					// console.log('loop', mappedOptions, objValue)
					const opt = mappedOptions?.find(option => option.value==objValue.value)
					if (opt) {
						map.push(opt)
					}
					return map;
				}, [])
				//mappedOptions?.filter(option =>value.includes(option.value))
				: mappedOptions?.find(option => option.value==value)
			// const values = isMulti
			// 	? mappedOptions?.reduce(function(map, obj) {
			// 		if (value.indexOf(obj.value)>-1)
			// 			map.push(obj)
			// 		return map
			// 	}, [])
			// 	: mappedOptions?.find(option => option.value==value)
			//console.log('values', {values, value, mappedOptions})
			// console.log('values', values)
			setMappedValue(values || [])
		} else {
			setMappedValue([])
		}
	}, [mappedOptions, value, props.value])


	useEffect(() => {
		//console.log(`${props.name} changed `, props.modelValue)
		if (props.value)
			setValue(props.value)
	}, [props.value])

	useEffect(() => {
		if (listId) {
			setLoading(true)
			loadOptions(listId)
				.then((res) => setOptions(res))
				.finally(() => {
					setLoading(false)
				})
		}
	}, []);

	const selectedSkin = useMemo(() => customStyles(props.cssSkin, props?.form), [props.cssSkin])
	const SelectComponent = isCreatable ? CreatableSelect : Select


	return <SelectComponent
		styles={selectedSkin}
		// menuPlacement={Device.isMobile ? "top" : "auto"}
		menuPlacement={"auto"}
		tabSelectsValue={false}
		// isDisabled={disabled}

		// menuPortalTarget={document.body}
		//menuPortalTarget={document.querySelector(`[data-id="form-control-${props.id}"]`)}

		// menuPortalTarget={props.menuPortalTarget
		// 	|| (
		// 		Device.isMobile
		// 			? document.querySelector('main-content')
		// 			: document.body
		// 	)
		// }
		menuPortalTarget={props.menuPortalTarget || document.body}
		// menuPortalTarget={props.menuPortalTarget || document.querySelector(`.custom-form-${props.formId}`)}
		classNamePrefix="select2-selection"
		menuShouldScrollIntoView={false}
		menuShouldBlockScroll={false}
		{...props}

 		components={{
 			ValueContainer: CustomValueContainer,
			MultiValue: MultiValueOption,
			Option:CustomOption,
			SingleValue:CustomSingleValue,
			ClearIndicator:CustomClearIndicator,
			MultiValueRemove : !isClearable ? CustomMultiValueRemove : MultiValueRemove,
			...disabled ? {
				DropdownIndicator: () => null,
				Menu: () => null,
				Input: () => null,
			} : {},
        }}
		isClearable={isClearable}

		getOptionLabel={(option)=>`${option.groupLabel} ${option.label}`}
  		getOptionValue={(option)=>option.value}

		options={mappedOptions || []}
		// value={mappedValue || ''}
		value={mappedValue}
		//value={{value:'AM', label:'none'}}
		placeholder={mappedValue?.caption || props.placeHolder || ''}
		inputId={props.inputId}

		// components={{
		// 	Option:IconOption,
		// 	MultiValue: MultiValueOption,
		// }}
		// getOptionLabel={(props) => {
		// 	const { icon, label } = props;
		// 	return (
		// 		<span className="d-flex align-items-center ">
		// 			{icon && <span className="pe-2"><props.icon height={20} /></span>}
		// 			<span>{label}</span>
		// 		</span>
		// 	) ;
		// }}
		onFocus={handleFocus}
		onBlur={handleBlur}
		onCreateOption={handleCreateOption}
		// onClick={() => setMenuIsOpen(true)}
		// menuIsOpen={menuIsOpen}

		//value=""
		// onInputChange={(value, action) => {
		// 	if (action.action !== "input-blur" && action.action !== "menu-close") {
		// 		//console.log('onInputChange', value)
		// 		//setValue(value)
		// 	}
		// 	// only set the input when the action that caused the
		// 	// change equals to "input-change" and ignore the other
		// 	// ones like: "set-value", "input-blur", and "menu-close"
		// 	//if (action.action === "input-change") setValue(value); // <---
		// }}

		// onBlurResetsInput={false}
		// type={"text"}


		autoComplete="disabled"
		onKeyDown={handleKeyDown}

		openMenuOnClick={true}


		isMulti={isMulti}
		// onFocus={handleFocus}
		onChange={handleChange}
		noOptionsMessage={noOptionsMessage}
		formatCreateLabel={userInput => `Adicionar filtro "${userInput}"`}


	/>
}

export default CustomSelect;
