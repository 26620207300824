import React, {useState, useMemo, useEffect, useCallback} from "react"


import Fazla from "../../assets/images/static/products/fazla.png"
import Vollu from "../../assets/images/static/products/vollu.png"
import DompaCorp from "../../assets/images/static/products/dompacorp.png"
import DompaPromo from "../../assets/images/static/products/dompapromo.png"
import {dataProductsFazla} from "./data/fazla";
import {dataProductsVollu} from "./data/vollu";
import {dataProductsDompaCorp} from "./data/dompa-corp";
import {dataProductsDompaPromo} from "./data/dompa-promo";

export const list = () => {
	return [
		dataProductsFazla,
		dataProductsVollu,
		dataProductsDompaCorp,
		dataProductsDompaPromo,
	]
};

export const get = (id) => {
	return list().find(el => el.id===id)
};

