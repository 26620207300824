import React, {useState, useMemo, useEffect, useCallback} from "react"

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import "./our-services.scss"
import Circles from "../Circles";

import CardUltraCRM from "../../../../assets/images/static/our-services/ultra-crm.jpg"
import CardCashback from "../../../../assets/images/static/our-services/cashback.jpg"
import CardCampanhasPromocionais from "../../../../assets/images/static/our-services/campanhaspromocionais.jpg"
import CardSocialListening from "../../../../assets/images/static/our-services/sociallistening.jpg"
import CardECommerce from "../../../../assets/images/static/our-services/ecommerce.jpg"
import CardHotSitesPromocionais from "../../../../assets/images/static/our-services/hotsitespromocionais.jpg"
import CardAnaliseBI from "../../../../assets/images/static/our-services/analisebi.jpg"
import CardIA from "../../../../assets/images/static/our-services/ia.jpg"
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg"

const Card = (
	{
		title,
		description,
		onClick,
		image,
		descriptionPaddingRight = "0rem",
		actions,
	}
) => {
	return (
		<div
			className="card d-flex flex-column position-relative"
			onClick={onClick}
		>
			<div className="card-title text-32 fw-medium flex-grow-0 debug1">{title}</div>
			<div className="card-description text-18 flex-grow-1 flex-fill pt-3 debug2" style={{paddingRight:descriptionPaddingRight}} >{description}</div>
			<div className="boxed position-absolute card-image z-n1 debug3" style={{backgroundImage:`url(${image})`}} />
			<div className="position-absolute card-icon-badge z-1 debug3">
				<div className="card-icon-container">
					<IconNewWindow width={"100%"} height={"100%"} />
				</div>
			</div>
		</div>
	)
}
const OurServices = (
	{
		actions,
	}
) => {

	const navigate = useNavigate()

	const cards = useMemo(() => [
		{
			id:"ultra-crm",
			title:"UltraCRM",
			description:`Com um modo de cadastro inovador, analisa profundamente o perfil e a personalidade dos usuários, entregando comunicação e conteúdo adaptados às características de cada cliente.`,
			image:CardUltraCRM,
			descriptionPaddingRight : "3rem",
		},
		{
			id:"cashback",
			title:"Cashback",
			description:"Estratégia que devolve parte do valor gasto aos clientes, incentivando novas compras e fidelização.",
			image:CardCashback,
			descriptionPaddingRight : "6rem",
		},
		{
			id:"campanhas-promocionais",
			title:"Campanhas Promocionais",
			description:<>Título de capitalização,<br/>sorteios, promoções comerciais, vale-brinde.</>,
			image:CardCampanhasPromocionais,
			descriptionPaddingRight : "6rem",
		},
		{
			id:"social-listening",
			title:"Social Listening",
			description:"Monitoramos redes sociais para capturar opiniões, identificar tendências e analisar o comportamento do público.",
			image:CardSocialListening,
			descriptionPaddingRight : "6rem",
		},
		{
			id:"e-commerce",
			title:"E-Commerce",
			description:"Solução completa que facilita vendas recorrentes, gerencia pedidos de forma eficiente e otimiza a experiência do cliente.",
			image:CardECommerce,
			descriptionPaddingRight : "6rem",
		},
		{
			id:"hotsite-promocial",
			title:"Hotsite Promocional",
			description:"Desenvolvemos páginas exclusivas e estratégicas para potencializar campanhas, criando experiências interativas e engajantes que conectam marcas ao público de forma única.",
			image:CardHotSitesPromocionais,
			descriptionPaddingRight : "3rem",
		},
		{
			id:"analise-de-dados-e-bi",
			title:"Análise de Dados e BI",
			description:"Transformamos dados em relatórios precisos e insights estratégicos, auxiliando empresas a tomarem decisões informadas.",
			image:CardAnaliseBI,
			descriptionPaddingRight : "7rem",
		},
		{
			id:"inteligencia-artificial",
			title:"Inteligência Artificial",
			description:"Utilizamos um algoritmo próprio baseado em Machine Learning para desenvolver automações e funcionalidades avançadas.",
			image:CardIA,
			descriptionPaddingRight : "3rem",
		},
	], [])

	return <div className="our-services debug1 d-flex flex-row justify-content-center position-relative">
		<div className="d-flex flex-row block-content " id="servicos">

			{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
			{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

			<div className="d-flex flex-column content z-2">
				<div className="text-60 flex-grow-0 debug2 title">
					Nossos Serviços
				</div>
				<div className="cards flex-grow-1 flex-fill d-flex flex-row flex-wrap z-3 debug1">
					{cards && cards.map((card, index) =>
						<Card
							key={index}
							onClick={() => navigate(`/servicos/${card.id}`)}
							{...card}
						/>
					)}
				</div>
			</div>


			<div className="position-absolute boxed w-100 z-0 debug4 overflow-hidden"
			>
				<Circles
					Image={CirclesImage2}
					rotateX={0}
					rotateY={0}
					rotate={-45}
					width={580}
					height={480}
					top={50}
					right={-380/2}
				/>
				<Circles
					Image={CirclesImage1}
					rotateX={0}
					rotateY={0}
					rotate={45}
					width={440}
					height={440}
					bottom={0}
					left={-550/4}

				/>
			</div>
		</div>
	</div>
}




export default OurServices;
