import React from "react"
import {isCnpj, isCpf} from "../../helpers/validation_helper";
import {dateBrToISO, MoneyFormat, onlyNumbers} from "../../helpers/utils";
import {parseMoney, parsePercent} from "../../helpers/parser_helper";

import moment from "moment"
moment.locale('pt-BR')

const _ = require('lodash')

export const isEmptyFieldValue = (fieldValue) => {
	//fieldValue = fieldValue.toString()
	return (
		!fieldValue
		|| fieldValue === '(  )      -    '
		|| fieldValue === '(  )     -    '
		|| fieldValue === '__/__/____'
		|| fieldValue === 'R$ 0'
		|| fieldValue === 'R$ 0,00'
		|| fieldValue === '   .   .   -         '
		|| fieldValue === '  .   .   /    -  '
	)

}

export const isEmptyMoney = ({value, props}) => {
	const parsedValue = value ? MoneyFormat(parseMoney(value), 'R$ ', props.maskOptions?.allowDecimal?2:0) : ""
	return isEmptyFieldValue(parsedValue)
}

export const validatePassword8Char = (password, props) => {
	return password?.length >= 8
}

export const validatePasswordStrong = (password, props) => {
    if (
      password === undefined ||
      password === null ||
      password === "" ||
      password.length < 8
    ) {
		return false;
    }
    // This regex checks if there is at least one special char in the string.
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharsRegex.test(password)) {
		return false;
    }
    // This regex checks if there is at least one capital char in the string.
    const capitalCharRegex = /\w*[A-Z]\w*/;
    if (!capitalCharRegex.test(String(password))) {
    	return false;
    }
    // This regex checks if there is at least one numeric char in the string.
    const numericCharRegex = /\d+/;
    if (!numericCharRegex.test(String(password))) {
		return false;
    }
    return true;

}

export function isValidCep({value, props}) {
	const pValue = value ? value : '';
	const isEmpty = (pValue=='_____-___') || !pValue
	if (props.required && isEmpty) {
		return 'Campo necessário'
	} else if (!props.required && isEmpty) {
 		return true
	}

	return pValue?.replace(/[^\d]+/g,'').length==8 ? true : props.errorMessage || 'CEP Inválido'
}

export const requiredIfPF = (values, form) => {
	if (values.client.welfare!='0')
		return false
	return !values.client.document || isCpfFormat(values.client.document) ? true : false
}

export const requiredIfNotCapable = (values, form) => {
	if (values.client.birthDate=='27/08/2010')
		return false

	return true
}

export const isCpfFormat = (value) => {
	const res = onlyNumbers(
			value?.replace(/[^\d]+/g,'').trim()
		)
		.toString()
		.length
	return res< 12
}

export const isValidCpfCnpj = ({value, props}) => {
	const pValue = value ? value : '';

	//const isEmpty = (pValue=='___.___.___.__') || (pValue=='__.___.___/____-__') || !pValue.trim()
	const isEmpty = isEmptyFieldValue(pValue)

	if (props.required && isEmpty) {
		return 'Campo necessário'
	} else if (!props.required && isEmpty) {
		return true
	}

	if (isCpfFormat(pValue)) {
		return isCpf(pValue) ? true : props.errorMessage || 'Informe um CPF válido'
	} else {
		return isCnpj(pValue) ? true : props.errorMessage || 'Informe um CNPJ válido'
	}
}

export const isValidCpf = ({value, props}) => {
	const pValue = value ? value : '';

	if (props.required && !pValue) {
		return props.requiredFeedback || 'Campo necessário'
	} else if (!props.required && !pValue) {
		return true
	}

	return isCpf(pValue) ? true : props.invalidFeedback || 'Informe um CPF válido'
}

export const isValidCnpj = ({value, props}) => {
	const pValue = value ? value : '';

	if (props.required && !pValue) {
		return props.requiredFeedback || 'Campo necessário'
	} else if (!props.required && !pValue) {
		return true
	}

	return isCnpj(pValue) ? true : props.invalidFeedback || 'Informe um CNPJ válido'
}

export const isValidDate = ({value, props}) => {
	const pValue = value;

	if (props?.required && !pValue)
		return props.requiredFeedback || "Campo necessário"

	if (!props?.required && !pValue)
		return true

	if (dateBrToISO(pValue))
		return true
	else
		return props.invalidFeedback || "Data inválida"

}

export const isValidDateRange = ({value, props}) => {
	//return true
	const pLength = typeof value=='object' ? value.length : 0;

	// console.log('isValidDate', {value, pLength})
	if (props.required && pLength<2)
		return props.requiredFeedback || "Campo necessário"

	if (props.required) {
		if (moment(value[0]).isValid() && moment(value[1]).isValid())
			return true
		else
			return props.invalidFeedback || "Período inválido"
	}
	return true

}

export const isValidTime = ({value, props}) => {
	//return true
	//console.log('isValidTime', value)
	const pValue = value ? value.trim() : '';

	if (props.required && !pValue)
		return "Campo necessário"

	const pValues = pValue.split(':')

	const hour = parseInt(pValues[0])
	const minute = parseInt(pValues[1])

	if (!pValues.length || value.indexOf('_')>0 || isNaN(minute) || isNaN(hour) )
		return "Horário inválido"

	if (hour>23)
		return "Hora não pode ser maior que 23"

	if (minute>59)
		return "Minutos não pode ser maior que 59"

	return true

}

export const isValidEmail = ({value, props}) => {
	const pValue = value ? value.trim() : '';
	if (pValue==='[duplicated]') {
		return props.duplicatedFeedback || "E-mail já registrado"
	}

	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	if (props.required && !pValue)
		return props.requiredFeedback || "Campo necessário"

	if (!pValue || re.test(pValue))
		return true
	else
		return props.invalidFeedback || "E-mail inválido"

}

export const isValidMoney = ({value, props}) => {
	const parsedValue = parseMoney(value)

	if (props.required) {
		if (!parsedValue)
			return props.requiredFeedback || 'Valor obrigatório'
		if (props.minValue) {
			if (parsedValue < props.minValue)
				return `Valor mínimo ${MoneyFormat(props.minValue, 'R$ ', 2)}`
		}
	} else {
		if (props.minValue && parsedValue) {
			if (parsedValue < props.minValue)
				return `Valor válido a partir de ${MoneyFormat(props.minValue, 'R$ ', 2)}`
		}

	}
	return true



}

export const isValidPercent = ({value, props}) => {
	const parsedValue = parsePercent(value)

	if (!props.required) {
		return !isNaN(parsedValue)
			? true
			: props.requiredFeedback || 'Digite uma porcentagem válida'
	}

	return !isNaN(parsedValue) && parsedValue!=0
		? true
		: props.invalidFeedback || `Campo obrigatório`


}

export const isValidPhoneNumber = ({value, props}) => {
	const parsedValue = value?.replace(/[^\d]+/g,'') || '';

	let validated
	if (!props.required && !parsedValue) {
		return true
	} else {
		if (props.required && !parsedValue)
			return props.requiredFeedback || "Campo necessário"

		validated = parsedValue?.length >= 2 && (parsedValue?.[2] === "9")
			? parsedValue.length==11
			: parsedValue.length==10
	}

	return validated ? true :  props.invalidFeedback || `Digite um número de telefone válido`
}

export const isValidNumber = ({value, props}) => {
	const pValue = Number((value || '').toString().trim() || 0);
	//console.log('pValuenumber', pValue)
	//if (value==='0') return true
	// console.log('props.minValue', props.minValue, pValue)

	if (props.required) {
		if  ((value||'').toString().trim()==='')
			return props.requiredFeedback || 'Campo necessário'
	}

	if (props.minValue ) {
		if (pValue < props.minValue)
			return `Valor deve ser maior ou igual a ${props.minValue}`
	}

	if (props.maxValue ) {
		if (pValue > props.maxValue)
			return `Valor deve ser menor ou igual a ${props.maxValue}`
	}

	if (props.minLength && pValue.length<props.minLength) {
		return `Mínimo de ${props.minLength} dígitos`
	}

	return !isNaN(pValue)
		? true
		: props.invalidFeedback || `Valor inválido`

}

export const isValidValue = ({value, props}) => {

	//console.log({props, value})
	const pValue = value ? value?.toString().trim() : '';

	if (props.required && !pValue)
		return props.requiredFeedback || 'Campo necessário'

	return true

}

export const isValidPasswordStrong = ({value, props, values}) => {

	const {
		password=null,
	} = values || {}

	return (validatePasswordStrong(password, props, values) )
		? true
		: props.invalidFeedback || "Escolha uma senha válida"
}

export const isValidPassword8Char = ({value, props, values}) => {

	const password = values[props.name]

	return (validatePassword8Char(password, props) )
		? true
		: props.invalidFeedback || "Escolha uma senha válida"
}


export const validateConfirmPassword = ({value, props, values}) => {

	const {
		password=null,
		confirm_password=null,
	} = values || {}

	// alert(confirm_password)

	if (password && !confirm_password)
		return "Confirme a nova senha"

	if (confirm_password && password !== confirm_password)
		return "A confirmação de senha está incorreta"

	// return (validatePasswordStrong(confirm_password, props, values))
	// 	? true
	// 	: "Escolha uma senha válida"
	return (validatePassword8Char(confirm_password, props, values))
		? true
		: "Escolha uma senha válida"
}

export const isValidPassword = ({value, props}) => {

	//console.log({props, value})
	const pValue = value ? value?.toString().trim() : '';

	if (props.required) {
		if (!pValue)
			return props.requiredFeedback || "Campo necessário"

		return pValue.length>0
			? true
			: props.invalidFeedback || 'Preencha corretamente'
	}

	return true

}

export const isValidOption = ({value, props, values}) => {
	// console.log({value, props, values})

	if (typeof  value === 'object') {
		if (props.required) {
			return value?.length>=(props.min || -1)
				? true
				: props.requiredFeedback || `Escolha no mínimo ${props.min} ${value?.length===0 ? "opção" : "opções"}`
		}

	} else {
		if (props.required) {
			return value
				? true
				: props.requiredFeedback || 'Escolha uma opção'
		}
	}

	return true

}

export const isValidOptions = ({value, props}) => {
	//console.log({props, value})
	const pValue = value ? value : [];

	if (props.required) {
		return pValue
			? pValue.length>0
			: 'Campo necessário'
	}

	return true

}

export const isValidUpload = ({value, props}) => {
	const pValue = value ? value?.toString().trim() : '';

	if (props.required) {
		return pValue
			? true
			: 'Escolha um arquivo'
	}

	return true

}

