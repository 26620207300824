import React, {} from "react"

// import {ReactComponent as LogoWhite} from "../../assets/images/logos/logo-white.svg"
// import Avatar from "../../../assets/images/static/avatar.png"
// import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';

const ClassNameImage = ({className, iconClass, style, ...props}) =>
	<div className={className}>
		<div
			style={{
				...style,
				minHeight:style.height,
				minWidth:style.width,
			}}
		>
			<div
				className={`${iconClass} `}
				style={{
					fontSize:Math.round(style.height+style.width)/2,
				}}
			/>
		</div>
	</div>

const MultiImage = (
	{
		Tag = "div",
		src,
		width, height,
		backgroundSize="contain",
		backgroundOrigin="padding-box",
		style,
		onClick,
		id,
		iconClass,
		className='',
		ImageComponent,
		alt='',
		...props
	}
) => {

	if (ImageComponent)
		return <ImageComponent width={width} height={height} onClick={onClick}/>

	if (iconClass && !src)
		return <ClassNameImage iconClass={iconClass} style={style} className={className} onClick={onClick}/>

	return <>
		{0 && (typeof src === 'object' || (typeof src === 'string' && (src.indexOf('lottie')>-1)))
			? <Tag
					src={src}
					className={className}
					loop={true}
					autoplay={true}
					style={{ width, height, ...style }}
					onClick={onClick}
					alt={alt}
					{...props}
				/>
			: <Tag
				// src={src}
				// alt={alt}
				className={className}
				onClick={onClick}
				type={Tag === 'button' ? "button" : undefined}
				id={id}
				style={{
					width,
					height,
					...style,
					backgroundImage:`url(${src})`,
					backgroundSize:backgroundSize,
					// backgroundClip: "content-box",
					backgroundRepeat:'no-repeat',
					backgroundPosition:"center",
					backgroundOrigin,
				}}
				{...props}
			>{props.children}</Tag>

		}
	</>

}

export default MultiImage

//
