import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./dompa-media.scss"
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
// import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"

import UOL from "../../../../assets/images/static/media/uol.png"
import Polinize from "../../../../assets/images/static/media/polinize.png"
import SalaNoticia from "../../../../assets/images/static/media/salanoticia.png"

import NewsOne from "../../../../assets/images/static/media/news/one.png"
import NewsTwo from "../../../../assets/images/static/media/news/two.png"
import NewsThree from "../../../../assets/images/static/media/news/three.png"
import Button from "../../layout/Button/Button";



const DompaMedia = (
	{
		...props
	}
) => {

	const logos = useMemo(() => [
		{
			label : "UOL",
			image : UOL,
			href : "http://google.com",
		},
		{
			label : "Polinize",
			image : Polinize,
			href : "http://google.com",
		},
		{
			label : "Sala da Notícia",
			image : SalaNoticia,
			href : "http://google.com",
		},
	], [])

	const news = useMemo(() => [
		{
			title : "Você não sabe, mas estes golpes usam IA para enganar você; aprenda a evitar",
			description : "Da ligação à mensagem de texto via WhatsApp, os golpes financeiros vêm se aperfeiçoando a cada dia. E a nova abordagem da vez é usar IA (Inteligência Artificial) para ludibriar as vítimas, já que a estratégia dá uma cara mais profissional às fraudes",
			image : NewsOne,
			href : "https://www.uol.com.br/tilt/noticias/redacao/2024/02/21/uso-inteligencia-artificial-golpes-virtuais.htm?cmpid=copiaecola",
		},
		{
			title : "Dompa anuncia lançamento de aplicativo de gamificação que visa premiar usuários em jornadas de autoconhecimento",
			description : "A Dompa, primeira plataforma 360 no Brasil com objetivo de revolucionar o segmento de sorteios legais por meio de Inteligência Artificial, anuncia o lançamento do seu novo produto, o Vollu, aplicativo de gamificação por assinatura que atua como um guia personalizado para o autoconhecimento.",
			image : NewsTwo,
			href : "http://google.com",
		},
		{
			title : "Oito dicas para organizar sorteios bem-sucedidos",
			description : "A prática de sorteios online popularizou-se nas redes sociais, não apenas como meio de promover produtos, mas também como uma estratégia eficaz para envolver os usuários. Nesse contexto, compreender as leis e adotar práticas transparentes, em conformidade com as normas vigentes, torna-se essencial para assegurar o sucesso de um sorteio.",
			image : NewsThree,
			href : "http://google.com",
		},
	], [])

	const mainNews = useMemo(() => news.slice(0)[0], [news])
	const subNews = useMemo(() => news.splice(1, 2), [news])

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative ">
			<div className="dompa-media d-flex justify-content-center flex-column debug1 block-content">

				<div className="d-flex flex-row">
					<div className="main-title flex-grow-0">
						Dompa na mídia
					</div>
					<div className="mid-line-container flex-grow-1 flex-fill d-flex justify-content-center align-items-center">
						<div className="mid-line debug1"/>
					</div>
					<div className="logos d-flex flex-row flex-grow-0 justify-content-center align-items-center gap-4">
						{logos && logos.map(logo => <img key={logo.label} src={logo.image} height={44}/>)}
					</div>
				</div>

				<div className="news-container d-flex flex-row debug3 pt-4">

					<div className="main-news flex-grow-0 debug2 ">
						<div className="d-flex flex-column ">
							<div
								className="main-news-container d-flex flex-column justify-content-end position-relative debug4"

							>
								<div className="title text-32 fw-medium flex-grow-0 z-2 debug1">{mainNews.title}</div>
								<div className="description text-18 flex-grow-0 pt-3 z-2 debug2" style={{paddingRight:0}} >{mainNews.description}</div>
								<div className="image boxed position-absolute z-n1 debug3" style={{backgroundImage:`url(${mainNews.image})`}} />
								<div className="position-absolute boxed main-gradient z-1"/>
							</div>
							<div className="actions debug2 py-1 d-flex justify-content-end">
								<Button
									href={mainNews.href}
									target={"_blank"}
									template={"link-underline-purple"}
									size={"medium"}
									label={"Leia a Matéria"}
									IconLeft={IconNewWindow}
									iconHeight={14}
									iconWidth={16}
								/>
							</div>
						</div>

					</div>

					<div className="sub-news flex-grow-1 flex-fill debug2 d-flex flex-column">

						{subNews && subNews.map(news =>
							<div key={news.title} className="d-flex flex-row sub-news-container gap-4 debug4">
								<div className="d-flex flex-column ">
									<div
										className="image-container d-flex flex-column justify-content-end position-relative debug4 position-relative "

									>
										<div className="image boxed position-absolute z-n1 debug3" style={{backgroundImage:`url(${news.image})`}} />
										<div className="position-absolute boxed sub-gradient z-1"/>
									</div>
								</div>
								<div className="d-flex flex-column">
									<div className="title fw-medium flex-grow-0 z-2 debug1 text-18">{news.title}</div>
									<div className="description text-12 flex-grow-1 pt-3 z-2 debug2" style={{paddingRight:0}} >{news.description}</div>
									<div className="actions debug2 py-1 d-flex justify-content-end pt-2">
										<Button
											template={"link-underline-purple"}
											href={news.href}
											target={"_blank"}
											size={"medium"}
											label={"Leia a Matéria"}
											IconLeft={IconNewWindow}
											iconHeight={14}
											iconWidth={16}
										/>
									</div>
								</div>
							</div>
						)}

					</div>
				</div>
			</div>
		</div>
	)
}




export default DompaMedia;
