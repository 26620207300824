export const isChildRecursive = (parent, child) => {
    if (typeof parent === "string")
        parent = document.getElementById(parent);
    if (typeof child === "string")
        child = document.getElementById(child);


    if (!parent || !child) {
        return false;
    }

    function isDescendant(parent, child) {
        if (parent === child) {
            return true;
        }
        for (let i = 0; i < parent.children.length; i++) {
            if (isDescendant(parent.children[i], child)) {
                return true;
            }
        }
        return false;
    }

    return isDescendant(parent, child);
}

