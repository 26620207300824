import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/services/hotsite-promocial.jpg"
import SC from "../../../assets/images/static/services/sucess-cases/hotsite-promocial.png"

export const dataHotsitePromocional =
	{
		id : "hotsite-promocial",
		mainTitle : `HotSites\nPromocionais`,
		mainDescription : `Conexão Única entre Marcas e Público. Potencialize suas campanhas com páginas exclusivas, interativas e estratégicas.`,
		title : `Hot Sites\nExclusivos`,
		description : <>Os <strong>Hot Sites Promocionais</strong> da Dompa são páginas personalizadas desenvolvidas para potencializar campanhas e criar conexões significativas com o público.<br/>Oferecemos experiências interativas que destacam a identidade da marca e aumentam o engajamento em ações promocionais.</>,
		image : IMG,
		functionsSubTitle : `Como Nossos Hot Sites Podem Potencializar Sua Campanha?`,
		functions : [
			{
				title : `Design Personalizado`,
				description : `Criação de páginas exclusivas, alinhadas à identidade visual da marca`,
			},
			{
				title : `Experiência Interativa`,
				description : `Funcionalidades dinâmicas que incentivam a participação do público`,
			},
			{
				title : `Integração de Ferramentas`,
				description : `Conexão com CRMs, sistemas de pagamento e redes sociais`,
			},
			{
				title : `Gestão em Tempo Real`,
				description : `Acompanhamento de métricas e desempenho da campanha diretamente na plataforma`,
			},
			{
				title : `Otimização para Mobile`,
				description : `Design responsivo para garantir uma experiência fluida em dispositivos móveis`,
			},
		],
		benefitsSubTitle : `Por Que Escolher Nossos Hot Sites Promocionais?`,
		benefits : [
			{
				title : `Engajamento\nElevado`,
				description : `Experiências imersivas que\ncapturam a atenção do público`,
			},
			{
				title : `Conexão\ncom a Marca`,
				description : `Comunicação direta e personalizada\ncom os consumidores`,
			},
			{
				title : `Resultados\nMensuráveis`,
				description : `Métricas claras para avaliar\no impacto da campanha`,
			},
			{
				title : `Flexibilidade\ne Criatividade`,
				description : `Soluções adaptadas para diferentes\ntipos de ações promocionais`,
			},
			{
				title : `Conversões\nAumentadas`,
				description : `Páginas otimizadas para\natrair e reter participantes`,
			},
		],
		applicationDescription:`Como Nossos Hot Sites Estão Transformando Campanhas?`,
		applications : [
			{
				title : `Campanhas de Lançamento`,
				description : `Páginas exclusivas para promover novos produtos`,
			},
			{
				title : `Sorteios e Promoções`,
				description : `Plataformas que gerenciam a participação do público e divulgam prêmios`,
			},
			{
				title : `Eventos Especiais`,
				description : `Hot sites dedicados a festivais, shows ou comemorações sazonais.`,
			},
			{
				title : `Captação de Leads`,
				description : `Ferramentas integradas para capturar dados de potenciais clientes`,
			},
		],
		successCase : {
			name : `Santo Mercado`,
			title : `Hot Sites em Ação`,
			image : SC,
			solution : `Desenvolvimento de um hot site\ninterativo com cadastro de notas fiscais e\ndistribuição de prêmios.`,
			challenge : `Criar uma plataforma\ndigital que engajasse\nvisitantes e gerenciasse a\nparticipação em campanhas\npromocionais`,
			percent : 29,
			percentExplanation : `Engajamento do público`,
		},
	}



