import React from "react"
import moment from "moment"
moment.locale('pt-BR')
export const expandOptions = (list, data) => {
	for (let x=0; x<list.length; x++) {
		list[x] = {...list[x], ...data}
	}
	return list
}

const _ = require('lodash')

//'allRafles','eachRaffle'
export const optionsCampaignMode = expandOptions([
	{caption:'Clientes participam de todos os sorteios', id:"allRafles", iconClass:"pi pi-arrows-alt",},
	{caption:'Cliente participa de cada sorteio que está inscrito', id:"eachRaffle", iconClass:"pi pi-arrows-h",},
], {type:"campaign-mode", groupLabel:"Modo da campanha"})

export const optionsCampaignModeMapped = _.keyBy(optionsCampaignMode, 'id')

export const mask9Digits = "(99) 99999-9999"
export const mask8Digits = "(99) 9999-9999"
export const maskUS = "(999) 999-9999"
