import React, {useState, useMemo, useEffect, useCallback} from "react"

import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import "./our-team.scss"
import Circles from "../Circles";

import ImageCEO from "../../../../assets/images/static/our-team/ceo.png"
import ImageCOO from "../../../../assets/images/static/our-team/coo.png"
import ImageCMO from "../../../../assets/images/static/our-team/cmo.png"
import ImageUIUX from "../../../../assets/images/static/our-team/ui-ux.png"
import ImageCopywriting from "../../../../assets/images/static/our-team/copywriting.png"
import ImageSocialMedia from "../../../../assets/images/static/our-team/social-media.png"

import nl2br from "react-nl2br";

const Card = (
	{
		Image,
		title,
		description,
		command,
		image,
		descriptionPaddingRight = "0rem",
		actions,
	}
) => {
	return (
		<div
			className="our-team-card d-flex flex-column position-relative w-100 debug5"

		>
			<div className="card-image boxed position-absolute z-n1 debug3" style={{backgroundImage:`url(${Image})`}} >
				<div className="boxed position-absolute card-image z-n1 debug3 d-flex flex-column justify-content-end">
					<div className="card-info-box d-flex flex-column">
						<div className="card-title flex-grow-0 debug2">{nl2br(title)}</div>
						<div className="card-description flex-grow-0 debug2" style={{paddingRight:descriptionPaddingRight}} >{nl2br(description)}</div>
					</div>
				</div>

			</div>
		</div>
	)
}
const OurTeam = (
	{
		actions,
	}
) => {

	const cards = useMemo(() => [
		{
			Image : ImageCEO,
			title:"CEO",
			description:`O time de operações assegura que todas as etapas das campanhas sejam executadas com excelência, garantindo qualidade e eficiência nos processos.`,
			descriptionPaddingRight : "1rem",
			command:e => {},
		},
		{
			Image : ImageCOO,
			title:"COO",
			description:`Assegura que todas as etapas das campanhas sejam executadas com excelência, garantindo qualidade e eficiência nos processos.`,
			descriptionPaddingRight : "1rem",
			command:e => {},
		},
		{
			Image : ImageCMO,
			title:"CMO & PRODUTO",
			description:`Lidera a estratégia de marketing, alinhando ações de comunicação aos objetivos de negócio. É responsável por fortalecer a marca, expandir seu alcance e impulsionar o crescimento da empresa.`,
			descriptionPaddingRight : "1rem",
			command:e => {},
		},
		{
			Image : ImageUIUX,
			title:"UX & UI",
			description:`Projeta interfaces digitais que oferecem experiências intuitivas, priorizando a funcionalidade e a estética.`,
			descriptionPaddingRight : "1rem",
			command:e => {},
		},
		{
			Image : ImageCopywriting,
			title:"COPYWRITING",
			description:`Responsável por traduzir a essência da marca em textos estratégicos, capazes de engajar o público e impulsionar conversões de maneira eficaz.`,
			descriptionPaddingRight : "1rem",
			command:e => {},
		},
		{
			Image : ImageSocialMedia,
			title:"SOCIAL MÍDIA",
			description:`Gerencia a presença digital da empresa utilizando criatividade para promover campanhas que fortalecem a identidade da marca e ampliam o alcance nas plataformas online.`,
			descriptionPaddingRight : "1rem",
			command:e => {},
		},
	], [])

	return <div className="our-team debug1 d-flex flex-row justify-content-center position-relative">
		<div className="d-flex flex-column block-content position-relative">

			{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
			{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

			<div className="d-flex flex-column content z-2">
				<div className="flex-grow-0 debug2 pre-title">
					Nosso sucesso é construído por uma equipe<br/>multidisciplinar que trabalha com dedicação e paixão! 
				</div>
				<div className="flex-grow-0 debug2 title font-tt">
					Nossa Equipe
				</div>
				<div className="cards flex-grow-1 flex-fill z-3 debug1">
					{cards && cards.map(card => <Card {...card} />)}
				</div>
			</div>

			<Circles
				// className="z-3"
				Image={CirclesImage1}
				rotateX={0}
				rotateY={0}
				rotate={45*2}
				width={720}
				height={720}
				left={'10%'}
				top={'31%'}

			/>
			<div className="title-together content z-2 font-tt">
				Juntos, nossos times colaboram para<br/>oferecer soluções completas!
				<span className="heart">💙</span>
			</div>


		</div>
	</div>
}




export default OurTeam;
