// import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/app.scss";

// import {AnimatePresence} from "framer-motion";
// import {AnimatePresence} from "framer-motion/dist/framer-motion";
import { environment, isLocalhost } from "./configuration";
import Routes from "./routes/routes";
const _ = require("lodash");

const App = (props) => {
	// const navigate = useNavigate()
	// const app = useOvermind("auth");

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		if (location.hash) {
			try {
				let hashname = location.hash.slice(1)
				document.getElementById(hashname).scrollIntoView()
			} catch (e) {
				alert(e?.toString())
			}

		}
		// console.log({location})
		// alert(location)
	}, [location])

	useEffect(() => {
		if (environment !== "production") {
			const handleKeyPress = (e) => {
				if (e.keyCode == 39) {
					// 39 = ' (press key single quote to see div outline borders for debug)
					document.body.classList.toggle("debug-on");
				}
			};
			document.body.addEventListener("keypress", handleKeyPress);
			return () =>
				document.body.removeEventListener("keypress", handleKeyPress);
		}
	}, []);

	return (
		<React.Fragment>
			<Routes />
		</React.Fragment>
	);
};

export default App;
