import "./button.scss"
import React, {useState, useMemo, useEffect, useCallback} from "react"

const Button = (
	{
		label,
		onClick,
		href,
		target,
		IconLeft,
		IconRight,
		type="button",
		template="blue",
		size="medium",
		iconWidth=24,
		iconHeight=20.,
		...props
	}
) => {
	const Tag = href ? "a" : "button"

	return (
		<Tag type={type} href={href} target={target} onClick={onClick} className="button" data-template={template} data-size={size}>
			{IconLeft && <div className="pe-2"><IconLeft width={iconWidth} height={iconHeight}/></div>}
			{label && <div>{label}</div>}
			{IconRight && <div className="ps-2"><IconRight width={iconWidth} height={iconHeight}/></div>}
		</Tag>
	)
}

export default Button;
