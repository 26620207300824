import React, {useState, useMemo, useEffect, useCallback} from "react"

import { useLocation, useNavigate, useParams } from "react-router-dom";
// import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
// import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import LogoDompa from "../../../../assets/images/logos/dompa-corp.png"
import "./footer.scss"
import services from "../../../../services";
import Button from "../Button/Button";

const Footer = (
	{
		actions,
	}
) => {
	const navigate = useNavigate()

	const [menu, setMenu] = useState(null)
	useEffect(() => {
		const products = services.products.list()
		const cases = services.cases.list()
		const allServices = services.services.list()
		setMenu([
			{
				id : 'products',
				path : item => navigate(`/produtos/${item.id}`),
				label : "Produtos",
				items : products,
			},
			{
				id : 'cases',
				path : item => navigate(`/clientes/${item.id}`),
				label : "Cases",
				items : cases,
			},
			{
				id : 'services',
				path : item => navigate(`/servicos/${item.id}`),
				label : "Serviços",
				items : allServices,
			},
		])
	}, [])

	return <div className="footer debug1 d-flex flex-row justify-content-center position-relative">
		<div className="d-flex flex-column block-content ">
			{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
			{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

				<div className="menu-container d-flex flex-row debug2 title">
				{menu && menu.map(group =>
					<div key={group.label} className="menu-box d-flex flex-column">
						<div className="title">{group.label}</div>
						{group?.items && group.items.map(menu =>
							<div key={menu.name || menu.title} className="item" onClick={() => group.path(menu)}>{menu.name || menu.mainTitle}</div>
						)}
					</div>
				)}
				</div>

				{/*<div className="cards flex-grow-1 flex-fill d-flex flex-row flex-wrap z-3 debug1">*/}
				{/*	{cards && cards.map(card => <Card {...card} />)}*/}
				{/*</div>*/}

			<div className="dompa-container d-flex flex-row debug2">
				<div className="logo-container flex-grow-0">
					<img src={LogoDompa} height={64} />
				</div>
				<div className="slogan flex-grow-1 flex-fill">
					Revolucionando o Mercado
					<br/>com Soluções
					<br/>inteligentes
				</div>
				<div className="actions flex-grow-0 align-items-center">
					<div className="d-flex flex-row gap-4">
						<Button
							template="light-blue"
							size={"medium"}
							label={"Contato"}
							onClick={() => navigate(`/#contato`)}
							// IconLeft={IconNewWindow}
							iconHeight={14}
							iconWidth={16}
						/>
						<Button
							template="light-blue"
							size={"medium"}
							onClick={() => navigate(`/#servicos`)}
							label={"Nossos Serviços"}
							// IconLeft={IconNewWindow}
							iconHeight={14}
							iconWidth={16}
						/>
					</div>
				</div>
			</div>

			<div className="line-box pt-5 d-flex flex-column" >
				<div className="line" >

				</div>
			</div>
			<div className="copyright pt-2 pb-5 text-center text-15 d-flex flex-column" >
				Dompa Entretenimento Ltda e 48.935.528/0001-89
			</div>

		</div>
	</div>
}




export default Footer;
