import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./service-applications.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
// import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
// import {ReactComponent as IconScreen} from "../../../../assets/images/icons/screen.svg"
import nl2br from "react-nl2br";
import {ReactComponent as CirclesImage} from "../../../../assets/images/circles-0.svg"
import ButtonCircleLeft from "../../../../assets/images/icons/circle-left.png"
import ButtonCircleRight from "../../../../assets/images/icons/circle-right.png"
import Circles from "../../../components/blocks/Circles";
import ServicesApplicationSwiper from "./ServicesApplicationSwiper";


const ServiceApplications = (
	{
		data,
		...props
	}
) => {

	const navigate = useNavigate()

	const cards = data.applications

	const handlePrevious = () => {
		const swiper = document.querySelector('.appSwiper')?.swiper;
		if (swiper)
			swiper.slidePrev();
	}

	const handleNext = () => {
		const swiper = document.querySelector('.appSwiper')?.swiper;
		if (swiper)
			swiper.slideNext();
	}

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative z-5 pt-5">
			<div className="service-applications d-flex flex-column debug1 block-content ">
				<div className="d-flex flex-row align-items-center">

					<div className="service-container flex-grow-1 flex-fill d-flex flex-column debug3 ">
						{/*<div className="debug2 icon-container">*/}
						{/*	<IconScreen width={72} height={72}/>*/}
						{/*</div>*/}
						<div className="pre-title debug2">
							Exemplos de
						</div>
						<div className="title debug2 font-tt">
							Aplicações
						</div>
						<div className="description debug4">
							{nl2br(data.applicationDescription)}
						</div>
						<div className="actions debug4 d-flex gap-4">
							<div className="button-swipe">
								<img src={ButtonCircleLeft} height={72} onClick={handlePrevious}/>
							</div>
							<div className="button-swipe">
								<img src={ButtonCircleRight} height={72} onClick={handleNext}/>
							</div>
						</div>
					</div>

					<div
						className="swipe-container flex-grow-0 d-flex flex-column debug4 debug2 position-relative justify-content-center"
					>
						<Circles
							className="z-0"
							Image={CirclesImage}
							width={606}
							height={606}
							top={"calc( 50% - 303px ) "}
							left={"calc( 50% - 303px ) "}
						/>
						<div className="cards z-1 swipe-container-inside">
							<ServicesApplicationSwiper className={"appSwiper"} cards={cards}/>
						</div>
						{/*<div className="position-absolute boxed sub-gradient z-1"/>*/}
					</div>
				</div>
			</div>
		</div>
	)
}




export default ServiceApplications;
