import React, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import {Container, Form, Row} from "reactstrap";
import {registeredFields} from "./FormFields";
import {autoFillForms, Device} from "../../configuration";
const _ = require("lodash")
const diff = require('deep-diff')

// let lastChangedValues = {}
export const useCustomForm = (
	{
		fields = [],
		topForm,
		initialValues,
		formId,
		onSubmit,
		readOnly=false,
		cssSkin = "form-admin",
		validateOnBlur = true,
		hideCurrentErrorTimeout = 3000,
		validateOnChange = true,
		onFormChange,
		onSetActiveCategory,
	}
) => {

	const [visibles, setVisibles] = useState({})
	const [firstError, setFirstError] = useState(null)
	const [focusField, setFocusField] = useState(null)
	const [activeCategory, setActiveCategory] = useState('info')
	const [lastChangedValues, setLastChangedValues] = useState(null)
	const [modified, setModified] = useState(null)

	// const handleSetActiveCategory = (categoryId) => {
	// 	setActiveCategory(categoryId)
	// 	// onSetActiveCategory && onSetActiveCategory(categoryId)
	// }
	// const [lastChangedValues, setLastChangedValues] = useState({})

	//make an empty entry for all fields that are not set in defaultValues, so formik.values is always consistent
	const aggregateInitialValues = {
		...fields.reduce((map, obj, index) => {
			if (autoFillForms) {
				const fieldType = registeredFields.find(el => el.type===obj.type)
				map[obj.name]=fieldType.debugValue || ""
			} else {
				map[obj.name]=''
			}
			return map
		}, {}),
		...initialValues,

	}

	// useEffect(() => {
	// 	console.log('new firstError', {firstError})
	// }, [firstError])

	const updateVisibles = values => {
		const visibles = {}

		for (const field of fields) {
			visibles[field.name] = field.isVisible
				? field.isVisible({
					value:values[field.name],
					props:field,
					values:values,
				})
				: true
		}

		setVisibles(visibles)
		return visibles
	}

	const validateField = (field, values) => {
		const validator =
			field.isValid || registeredFields.find(el => el.type===field.type)?.defaultValidator


		return validator
			? validator({
				value:values[field.name],
				props:field,
				values:values,
			})
			: false
	}

	const validate = values => {
		let firstError = null
		const errors = {}
		const visibles = updateVisibles(values)

		for (const field of fields) {
			const fieldType = registeredFields.find(el => el.type===field.type)
			if (!fieldType) {
				console.log(`FIELD TYPE NOT FOUND ON VALIDATE ${field.name} ${field.type}`)
				continue
			}
			const visible = visibles[field.name]

			if (!visible)
				continue
			if (fieldType.noInput)
				continue


			const error = validateField(field, values)

			if (!error || typeof error === 'string') {
				if (!firstError) {
					firstError = field.name
				}
				errors[field.name] = error
			}
		}

		setFirstError(firstError)

		// console.log('validate', {values, errors})
		return errors
	}

	// useEffect(() =>{
	// 	if (firstError) {
	// 		const interval = setTimeout(() => setFirstError(null), hideCurrentErrorTimeout)
	// 		return () => clearTimeout(interval)
	// 	}
	// }, [firstError])
	//

	const formik = useFormik({
		validateOnBlur,
		validateOnChange,
		initialValues:{...aggregateInitialValues},
		validate,
		onSubmit,
	})

	useEffect(() => {
		updateVisibles(aggregateInitialValues)
	}, [])

	useEffect(() => {
		if (formik.isSubmitting) {
			if (firstError) {
				try {
					const element = document.getElementById(`form-input-${firstError}`)
					const inputsContainer = element.getAttribute('data-inputs-container')
					const targetInput = element.getAttribute('data-target-input')

					const field = fields.find(el => el.name===firstError)
					if (field?.category)
					 	setActiveCategory(field.category)

					setTimeout(() => {
						if (inputsContainer) {
							try {
								if (element)
									element.querySelector(`INPUT`).focus()
							} catch(e) {
								console.log('Inputs containers not found either')
							}
						} else if (targetInput) {
							try {
								document.getElementById(targetInput).focus()
							} catch(e) {
								console.log('Target Inputs not found either')
							}
						} else {
							element.focus()
						}
					}, 1)
				} catch(e) {
					console.log(`FOCUS FAILED: can't find "form-input-${firstError}"`)
				}
			}
		}
	}, [formik.isSubmitting, firstError])

	useEffect(() => {
		if (!onFormChange)
			return

		const a = lastChangedValues || formik.values
		const b = formik.values
		const diffs = diff(a, b);

		// const isEqual = _.isEqual(a, b)
		if (diffs || !lastChangedValues) {
			if (onFormChange) {
				// console.log('changed')
				onFormChange({values:formik.values, formik:formik})
			}
			setLastChangedValues(b)
		}
		// console.log('a', JSON.stringify(a))
		// console.log('b', JSON.stringify(b))
		// console.log('diffs', JSON.stringify(diffs))


	}, [formik.values]) //, onFormChange

	useEffect(() => {
		updateVisibles(formik.values)
		// if (firstError) {
		// 	const field = fields.find(el => el.name===firstError)
		// 	if (field) {
		// 		//formik.validateField(field.name)
		// 		const error = validateField(field, formik.values)
		// 		if (error && typeof error !== 'string') {
		// 			setFirstError(null)
		// 		}
		// 	}
		// }
	}, [formik.values])
	return {...formik, visibles, firstError, focusField, cssSkin, setFocusField, setModified, activeCategory, setActiveCategory, topForm, readOnly}

}

const CustomForm = (
	{
		form,
		fields,
		style,
		initialValues,
		className='',
		...props
	}
) => {
	if (!form)
		return null

	return (
		<Form onSubmit={form.handleSubmit} className={`${form.cssSkin} ${form?.readOnly ? "read-only" : ""} ${className}`} style={style}>
			{/*<Container fluid >*/}
			{/*<Row >*/}
				{props.children}
			{/*</Row>*/}
			{/*</Container>*/}
		</Form>
	)

}

export default CustomForm
