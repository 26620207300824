import React, {useState, useMemo, useEffect, useCallback} from "react"
import Marquee from "react-fast-marquee";
import LogoBYD from "../../../../assets/images/company-logos/byd.png"
import Timao from "../../../../assets/images/company-logos/timao.png"
import Engemon from "../../../../assets/images/company-logos/engemon.png"
import SantoMercado from "../../../../assets/images/company-logos/santomercado.png"
import {cancelEvent, cancelEventPropagation} from "../../../../helpers/utils";
import "./our-clients.scss"

const OurClients = (
	{
		...props
	}
) => {

	const items = useMemo(() => [
		{
			label : "BYD",
			image : LogoBYD,
			href : "http://google.com",
		},
		{
			label : "Cortinthians",
			image : Timao,
			href : "http://google.com",
		},
		{
			label : "Engemon",
			image : Engemon,
			href : "http://google.com",
		},
		{
			label : "Santo Mercado",
			image : SantoMercado,
			href : "http://google.com",
		},
	], [])

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative pt-5 mt-5">
		<div className="d-flex flex-row block-content">
			<div className="flex-grow-1 debug2 text-60 font-tt " style={{flexBasis:"50%", fontWeight:600}}>Veja quem já está conosco</div>
			<Marquee className="marquee-logos debug2" autoFill={true}  style={{flexBasis:"50%"}} pauseOnHover={true} speed={100}>
				{items && items.map(item =>
					<a key={item.label} href={item.href} onClick={e => cancelEventPropagation(e)} target={"_blank"} title={item.label}>
						<div className="item debug2">
							<div
								className="image debug3 grayscale"
								style={{backgroundImage:`url(${item.image})`}}
							/>
						</div>
					</a>
				)}
			</Marquee>
		</div>
		</div>
	)
}

export default OurClients;
