import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./client-description.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import {ReactComponent as IconScreen} from "../../../../assets/images/icons/screen.svg"
import nl2br from "react-nl2br";
import {ReactComponent as CirclesImage} from "../../../../assets/images/circles.svg"
import Circles from "../../../components/blocks/Circles";
import ResultsImage from "../../../../assets/images/backgrounds/resultados.jpg";
import services from "../../../../services";
import Button from "../../../components/layout/Button/Button";

const ClientDescription = (
	{
		data,
		...props
	}
) => {

	const navigate = useNavigate()

	const clients = useMemo(() => services.clients.list(), [])

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative z-5 pt-5" style={{marginTop:"-4rem"}}>
			<div className="client-description d-flex flex-column debug1 block-content ">

				<div className="client-list d-flex flex-row justify-content-center align-items-center">
					{clients && clients.map(client =>
						<div onClick={() => navigate(`/clientes/${client.id}`)} className="item">{client.name}</div>
					)}
				</div>

				<div className="client-description-container d-flex flex-row ">

					<div className="flex-grow-1 flex-fill d-flex flex-column debug3 ">
						<div className="title debug2 text-128 font-tt">
							{nl2br(data.name)}
						</div>
						<div className="title-challenge debug2 text-128 font-tt">
							Desafios
						</div>
						<div className="description debug2 text-28 ">
							{nl2br(data.solution)}
						</div>
					</div>

					<div
						className="image-container flex-grow-0 d-flex flex-column debug4 debug3 position-relative"
					>
						<div className="image boxed position-absolute z-2 debug3"
							 style={{backgroundImage:`url(${data.image})`}}
						/>
						{/*<div className="position-absolute boxed sub-gradient z-1"/>*/}
					</div>
				</div>

				<div className="client-solution-container d-flex flex-row ">

					<div className="flex-grow-1 flex-fill d-flex flex-column debug3 ">
						<div className="title-solution debug2 text-128 font-tt">
							Soluções
						</div>
						<div className="description debug2 text-28 ">
							{nl2br(data.solution)}
						</div>
					</div>

					<div
						className="solution-items flex-grow-0 d-flex flex-column debug4 debug3 position-relative "
					>
						{data.solutionItems && data.solutionItems.map((solution, index) =>
							<div className="item d-flex flex-row align-items-center">
								<div className="bullet">{index+1}</div>
								<div className="item-description">{solution.description}</div>
							</div>
						)}

					</div>
				</div>

				<div className="results-container d-flex flex-column ">
					<div className="results-image-container debug3 position-relative z-n1" >
						<div className="results-image position-absolute boxed"
							 style={{backgroundImage:`url(${ResultsImage})`}}
						/>
						<div className="position-absolute boxed z-1 d-flex justify-content-center align-items-center">
							<div className="results-label font-tt">Resultados</div>
						</div>
					</div>
					<div className="results-description  debug3">
						{data.results}
					</div>
					<div className="results-actions  debug3 d-flex justify-content-center align-items-center">
						<Button
							template="light-blue"
							href={data.website}
							target={"_blank"}
							size={"medium"}
							label={"Acesse o site"}
							// IconLeft={IconNewWindow}
							iconHeight={14}
							iconWidth={16}
						/>

					</div>
				</div>
			</div>
		</div>
	)
}




export default ClientDescription;
