import React, {useEffect, useState} from "react"

// import {FormFeedback, FormGroup, Input} from "reactstrap";
// import {Input} from "reactstrap";
import InputMask from "react-input-mask";
import {onlyNumbers} from "../../../../helpers/utils";
import {mask8Digits, mask9Digits, maskUS} from "../../../../helpers/constants/staticLists";
import {Label} from "reactstrap";

const InputPhone = (
	{
		form,
		field,
		required,
		enabled,
		name,
		id,
		placeHolder,
		feedback = "Campo inválido",
		className='w-100',
		...props
	}
) => {

    const [mask, setMask] = useState('(99) 99999-9999')
	const value = form?.values ? form?.values[name] : null

    const handleKeyDown = (e) => {
    	const code = e.keyCode || e.which || e.key || e.code

		if (code===32 || code?.toString()?.toLowerCase()==="space") {
			e.preventDefault()
		}
	}

	useEffect(() => {
		if (field.countryCodeField)
			updateMask(value)
	}, [form.values[field.countryCodeField]])

	const updateMask = (value) => {
    	let newMask
		if (field.countryCodeField) {
			if (form.values[field.countryCodeField]==='55') {
				newMask = (value?.length >= 6 && (value?.[5] === "9" || value?.[5] === "_" || value?.[5] === " "))
					? mask9Digits
					: mask8Digits
			} else if (form.values[field.countryCodeField]) {
				newMask = maskUS
			}
		} else {
			newMask = (value?.length >= 6 && (value?.[5] === "9" || value?.[5] === "_" || value?.[5] === " "))
				? mask9Digits
				: mask8Digits
		}
		setMask(newMask)
	}

    const handleChange = async e => {
        const value = e.target.value
		updateMask(value)
		await form.setFieldValue(name, onlyNumbers(value))

        if (props.onChange) {
            props.onChange({value, form})
		}

    }

	return <>
			<InputMask
				className={className}
	            mask={mask}
	            maskChar=" "
				placeholder={placeHolder}
				name={name}
				id={id}
				type="text"
	            autoComplete="disabled"
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				onFocus={props.onFocus}
				inputMode="tel"
				value={value}
				disabled={!enabled}
			/>
			<Label for={id} >
				{field.label}
			</Label>
	</>

}

export default InputPhone

