import React, {useState, useMemo, useEffect, useCallback} from "react"

import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import "./our-values.scss"
import Circles from "../Circles";

import {ReactComponent as IconJogoLimpo} from "../../../../assets/images/static/our-values/icons/jogo-limpo.svg"
import {ReactComponent as IconBoraResolver} from "../../../../assets/images/static/our-values/icons/bora-resolver.svg"
import {ReactComponent as IconTodoMundoJunto} from "../../../../assets/images/static/our-values/icons/todo-mundo-junto.svg"
import {ReactComponent as IconFazSentido} from "../../../../assets/images/static/our-values/icons/faz-sentido.svg"
import {ReactComponent as IconBaseDados} from "../../../../assets/images/static/our-values/icons/base-dados.svg"
import {ReactComponent as IconResultado} from "../../../../assets/images/static/our-values/icons/resultado.svg"
import nl2br from "react-nl2br";

const Card = (
	{
		Icon,
		title,
		description,
		command,
		image,
		descriptionPaddingRight = "0rem",
		actions,
	}
) => {
	return (
		<div
			className="our-values-card d-flex flex-column position-relative w-100 debug5"

		>
			{Icon && <div className="card-icon flex-grow-0 debug1">
				<Icon width={64} height={64} />
			</div>
			}
			<div className="card-title flex-grow-0 debug2">{nl2br(title)}</div>
			<div className="card-description flex-grow-1 flex-fill pt-3 debug2" style={{paddingRight:descriptionPaddingRight}} >{nl2br(description)}</div>
		</div>
	)
}
const OurValues = (
	{
		actions,
	}
) => {

	const cards = useMemo(() => [
		{
			Icon : IconJogoLimpo,
			title:"Jogo Limpo",
			description:`Agimos com ética e clareza.\nA confiança está no centro de tudo o que fazemos, e a transparência é nossa regra de ouro.`,
			descriptionPaddingRight : "1rem",
			command:e => {},
		},
		{
			Icon : IconBoraResolver,
			title:"Bora Resolver",
			description:`Somos movidos por atitude! Arregaçamos as mangas, encaramos desafios e entregamos resultados que fazem a diferença.`,
			descriptionPaddingRight : "3rem",
			command:e => {},
		},
		{
			Icon : IconTodoMundoJunto,
			title:"Todo Mundo Junto",
			description:`Aqui ninguém cresce sozinho. Trabalhamos em equipe, ouvindo, apoiando e somando forças para alcançar o sucesso.`,
			descriptionPaddingRight : "3rem",
			command:e => {},
		},
		{
			Icon : IconFazSentido,
			title:`Faz Sentido?\nFaz Diferença!`,
			description:`Cada passo é pensado para criar impacto real. Nossa bússola é o propósito e a vontade de fazer a diferença.`,
			descriptionPaddingRight : "3rem",
			command:e => {},
		},
		{
			Icon : IconBaseDados,
			title:"Na Base dos Dados",
			description:`Nada de achismos! Nossas decisões são guiadas por dados, garantindo precisão, inteligência e inovação.`,
			descriptionPaddingRight : "3rem",
			command:e => {},
		},
		{
			Icon : IconResultado,
			title:`Menos Ego,\nMais Resultado`,
			description:`Focar no que importa: resolver problemas, comprometimento com a excelência em tudo o que fazemos, gerar valor e crescer juntos.`,
			descriptionPaddingRight : "3rem",
			command:e => {},
		},
	], [])

	return <div className="our-values debug1 d-flex flex-row justify-content-center position-relative">
		<div className="d-flex flex-column block-content ">

			{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
			{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

			<div className="d-flex flex-column content z-2">
				<div className="flex-grow-0 debug2 title font-tt">
					Nossos Valores
				</div>
				<div className="cards flex-grow-1 flex-fill z-3 debug1">
					{cards && cards.map(card => <Card {...card} />)}
				</div>
			</div>


		</div>
	</div>
}




export default OurValues;
