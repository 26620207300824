import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./top-menu-bar.scss"
import {ReactComponent as Logo} from "../../../../assets/images/logo.svg"
import {ReactComponent as Headset} from "../../../../assets/images/icons/head-set.svg"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {isChildRecursive} from "../../../../helpers/dom";
import {cancelEvent} from "../../../../helpers/utils";
// import AvatarMenu from "../Page/AvatarMenu";

const TopMenuBar = (
	{
		items,
	}
) => {

	const [showMenu, setShowMenu] = useState(false)
	const [topMenu, setTopMenu] = useState(null)

	const navigate = useNavigate()
	const getSelectedTopMenuLeft = () => {
		const item = document.querySelector(`.top-menu-item[data-label="${topMenu.label}"]`)
		if (item) {
			return item.offsetLeft - 8
		}
		return undefined
	}

	useEffect(() => {
		const handleHide = (e) => {
			if (!isChildRecursive("top-menu-bar", e?.target)) {
				// console.log(e?.target.id, e?.target)
				setShowMenu(false);
				cancelEvent(e)
			}
		};
		if (showMenu) {
			document.body.addEventListener("mouseover", handleHide);
			document.body.addEventListener("touchstart", handleHide);
		}
		return () => {
			document.body.removeEventListener("mouseover", handleHide);
			document.body.removeEventListener("touchstart", handleHide);
		};
	}, [showMenu]);

	const handleMouseOver = useCallback((e, menu) => {
		setShowMenu(true)
		setTopMenu(menu)
		cancelEvent(e)
	}, [])

	const handleMenuClick = useCallback((e, menu) => {
		if (menu.command) {
			setShowMenu(false)
			setTopMenu(null)
			menu.command(e)
		}
	}, [])

	return <>
		<div id='top-menu-bar' className="top-menu-bar debug3 d-flex flex-row justify-content-center ">
			<div className="top-menu-inside d-flex flex-row block-content debug2 position-relative">
				<div className="flex-grow-0 logo-container">
					<div className="logo-div" onClick={() => navigate('/')}>
						<Logo width={"100%"} height={"auto"} />
					</div>
				</div>
				<div className="flex-grow-1 flex-fill menu-container d-flex flex-row">

					{items && items.map((menu, index) => {
						// if (menu?.items?.length===1)
						// 	menu = menu?.items[0]
						return <div
							key={menu.label}
							onMouseOver={e => handleMouseOver(e, menu)}
							onClick={e => handleMenuClick(e, menu)}
							className="top-menu-item d-flex align-items-center"
							data-label={menu.label}
						>
							<div>{menu.label}</div>
						</div>
					})}
				</div>
				<a
					className="flex-grow-0 menu-avatar-container"
					href="https://api.whatsapp.com/send?phone=5511916207852&text=Ol%C3%A1,%20como%20podemos%20te%20ajudar"
					target={"_blank"}
				>
					<Headset width={20} height={20}/>
					{/*<AvatarMenu />*/}
				</a>

			{(topMenu?.items?.length>0) &&
			<div
				className={`top-menu-container d-flex flex-row  ${showMenu ? "open" : "closed"}`}
				style={{paddingLeft:getSelectedTopMenuLeft()}}
				// onMouseOver={e => handleMouseOver(e, topMenu)}
			>
				{(topMenu?.items?.length>0) && topMenu.items.map((menu) => {
					// if (!menu.items?.length)
					// 	return null

					return <div className="d-flex flex-column gap-2 debug3 sub-menu-container  ">
						{menu?.label && <div
							className="sub-menu-title"
							onClick={e => handleMenuClick(e, menu)}
						>
							{menu?.label || "empty"}
						</div>}
						<div className="sub-menu-items ">
							{menu.items?.length && menu.items.map(subMenu => {
								return <div
									className="sub-menu-item-final"
									onClick={e => handleMenuClick(e, subMenu)}
								>
									{subMenu.label || "empty"}
								</div>
							})}
						</div>

					</div>
				})}
			</div>
			}
			</div>
		</div>
	</>
}




export default TopMenuBar;
