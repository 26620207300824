import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/services/cashback.jpg"
import SC from "../../../assets/images/static/services/sucess-cases/cashback.png"

export const dataCacheback =
	{
		id : "cashback",
		mainTitle : `Cashback`,
		mainDescription : `Cashback: Fidelize Clientes com Benefícios Reais. Incentive compras e aumente o engajamento devolvendo parte do valor gasto ao cliente.`,
		title : `O Que é o\nCashback?`,
		description : `O Cashback é uma estratégia que devolve parte do valor gasto pelos clientes, incentivando novas compras e fortalecendo a fidelização.\nA Dompa utiliza tecnologia avançada para personalizar ofertas e alinhar os benefícios ao perfil de cada consumidor, garantindo resultados expressivos.`,
		image : IMG,
		functionsSubTitle : `Principais Funcionalidades do Cashback`,
		functions : [
			{
				title : `Devolução Personalizada`,
				description : `Alinhamos o valor do cashback ao perfil de compra do cliente`,
			},
			{
				title : `Incentivo à Recorrência`,
				description : `Aumente o retorno dos clientes ao oferecer benefícios atrativos.`,
			},
			{
				title : `Automação e Monitoramento`,
				description : `Controle automático de valores devolvidos e integração com relatórios de desempenho.`,
			},
			{
				title : `Engajamento Multicanal`,
				description : `Integre campanhas de cashback a aplicativos, e-mails e redes sociais.`,
			},
			{
				title : `Análises Avançadas`,
				description : `Insights detalhados sobre a performance do cashback e impacto nos clientes.`,
			},
		],
		benefitsSubTitle : `Por Que Escolher Nossa Solução de Cashback?`,
		benefits : [
			{
				title : `Fidelização\nGarantida`,
				description : `Conquiste clientes ao oferecer\nvantagens reais`,
			},
			{
				title : `Aumento do\nTicket Médio`,
				description : `Motive os consumidores a gastarem\nmais para obter maiores benefícios`,
			},
			{
				title : `Engajamento\nAumentado`,
				description : `Mantenha os clientes ativos com\nrecompensas personalizadas`,
			},
			{
				title : `Resultados\nMensuráveis`,
				description : `Acompanhe o impacto das campanhas\nde forma detalhada`,
			},
			{
				title : `Customização\nTotal`,
				description : `Crie ofertas alinhadas ao perfil e\ncomportamento de cada cliente`,
			},
		],
		applicationDescription:`Como o Cashback Está Transformando Negócios?`,
		applications : [
			{
				title : `Promoções de Varejo`,
				description : `Cashback como incentivo para clientes realizarem compras maiores`,
			},
			{
				title : `Campanhas de\nE-commerce`,
				description : `Devolução de parte do valor para fidelizar clientes online`,
			},
			{
				title : `Programas de Fidelidade`,
				description : `Cashback integrado a sistemas de pontuação, aumentando a retenção.`,
			},
			{
				title : `Eventos Promocionais`,
				description : `Ofertas exclusivas de cashback em campanhas temáticas`,
			},
		],
		successCase : {
			name : `Santo Mercado`,
			title : `Cashback em Ação`,
			image : SC,
			solution : `Implementação de\ncashback para compras acima de um\nvalor definido.`,
			challenge : `Incentivar compras e\nengajar visitantes.`,
			percent : 23,
			percentExplanation : `Aumento de ticket médio`,
		},
	}



