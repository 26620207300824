import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/clients/studiow.jpg"

export const dataClientsStudioW =
	{
		id : "studiow",
		name : `Studio W`,
		challenge : `Engajamento de clientes do Studio W, renomado salão de beleza, com uma campanha atrativa e oferecer prêmios exclusivos e experiências personalizadas de alto valor agregado.`,
		image : IMG,
		solution : `Confira os resultados das nossas campanhas e veja como transformamos ideias em ações que geram engajamento e impacto real.`,
		solutionItems : [
			{
				description : `Desenvolvimento de uma campanha impulsionada por inteligência artificial`,
			},
			{
				description : `Criação de uma mecânica que permitia aos clientes concorrerem ao “Tratamento Taylor Made"`,
			},
			{
				description : `Experiência imersiva de beleza a cada serviço realizado.`,
			},
		],
		results : `Alto engajamento dos clientes, motivados por prêmios exclusivos e reforço do posicionamento do Studio W como referência em inovação e personalização no segmento de beleza.`,
		website : `https://www.studiow.com.br/`,
	}



