import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./who-we-are.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"
import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import Button from "../../layout/Button/Button";

const WhoWeAre = (
	{
		...props
	}
) => {

	const navigate = useNavigate()

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative mt-5">
			<div className="who-we-are d-flex flex-row debug1 block-content mt-5">
				<div className="flex-grow-1 flex-fill d-flex flex-column debug3">
					<div className="title debug2 text-96">
						Quem Somos?
					</div>
					<div className="description debug2 text-28 ">
						A Dompa nasceu em 2020, com o espírito inovador de uma startup determinada a transformar o mercado de campanhas promocionais no Brasil, um setor gigante e culturalmente forte...
					</div>
					<div className="actions debug2 fw-medium">
						<Button
							template={"link-underline"}
							onClick={() => navigate('/quemsomos')}
							size={"medium"}
							label={"Saiba Mais"}
							IconLeft={IconNewWindow}
							iconHeight={14}
							iconWidth={16}
						/>

					</div>
				</div>
				<div className="image-container flex-grow-0 position-relative">
					<div className="position-absolute boxed w-100 z-2 debug4 "
					>
						<div className="position-absolute image-circle"
							 style={{

								 // transform:`translateX(${circleTranslateX}) translateY(${circleTranslateY})`
							 }}
						>
							<Circle
								width={"auto'"}
								height={"100%"}
							/>
						</div>
						<div className="position-absolute image-box overflow-hidden" >
							<div className="w-100 h-100 image" style={{backgroundImage:`url(${WhoWeAreImage})`}} />
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}




export default WhoWeAre;
