import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./service-success-cases.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import {ReactComponent as IconScreen} from "../../../../assets/images/icons/screen.svg"
import nl2br from "react-nl2br";
import {ReactComponent as CirclesImage} from "../../../../assets/images/circles.svg"
import Circles from "../../../components/blocks/Circles";

const ServiceSuccessCases = (
	{
		data,
		...props
	}
) => {

	const navigate = useNavigate()
	const sc = data.successCase

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative z-5 pt-5" >
			<div className="service-success-cases d-flex flex-column debug1 block-content ">

				<div className="flex-grow-1 flex-fill d-flex flex-column debug3 ">
					<div className="debug2 pre-title">
						Casos de sucesso
					</div>
					<div className="title debug2 text-128 font-tt">
						{nl2br(sc.title)}
					</div>
				</div>
				<div
					className="image-container flex-grow-0 d-flex flex-column debug4 debug3 position-relative"
				>
					<div className="image boxed position-absolute z-2 debug3"
						 style={{backgroundImage:`url(${sc.image})`}}
					/>
					{/*<div className="position-absolute boxed sub-gradient z-1"/>*/}
				</div>

				<div className="solution-container d-flex flex-row debug mt-1">
					<div className="solution-name flex-grow-0 font-tt">
						{sc.name}
					</div>
					<div className="solution-description flex-grow-1 d-flex justify-content-end">
						<span className="fw-bold">Solução: <span className="ps-1 fw-normal">{nl2br(sc.solution)}</span> </span>

					</div>
				</div>

				<div className="challenge-container d-flex flex-row debug ">
					<div className="solution-description flex-grow-1 d-flex justify-content-start">
						<span className="fw-bold">Desafio: <span className="ps-1 fw-normal">{nl2br(sc.challenge)}</span> </span>

					</div>
					<div className="challenge-result flex-grow-0 debug ">
						<div className="challenge-box d-flex flex-row debug2 align-items-center justify-content-end">
							<div className="challenge-icon">
								<IconScreen width={44} height={45} />
							</div>
							<div className="percentage font-tt">
								{sc.percent}%
							</div>
						</div>
						<div className="percent-explanation">{sc.percentExplanation}</div>
					</div>
				</div>
			</div>
		</div>
	)
}




export default ServiceSuccessCases;
