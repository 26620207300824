import React, {useState, useMemo, useEffect, useCallback} from "react"

import Bello from "../../assets/images/static/cases/bello.jpg"
import SantoMercado from "../../assets/images/static/cases/santomercado.png"
import BYD from "../../assets/images/static/cases/byd.png"
import Corinthians from "../../assets/images/static/cases/corinthians.png"
import Engemon from "../../assets/images/static/cases/engemon.jpg"
import StudioW from "../../assets/images/static/cases/studiow.jpg"

export const list = () => {
	return [
		{
			id : "bello-futvolei",
			name : "Bello FutVolei",
			title : "Uma campanha de brihar os olhos",
			description : `Em parceria com o Instituto Orsi e Bello, um dos maiores nomes do Futevôlei no país, realizamos uma campanha transformadora. O grande prêmio foi um PlayStation 5 folheado a ouro, uma verdadeira joia tecnológica, e uma exclusividade no mercado brasileiro.
			
Essa campanha não apenas atraiu a atenção dos participantes, mas também reforçou o nosso posicionamento como uma empresa que entrega possibilidades únicas e surpreendentes.`,
			image : Bello,
		},
		{
			id : "byd",
			name : "BYD",
			title : "Rumo à UEFA",
			description : `Em um desafio de apenas 7 dias, fomos convocados para lançar uma campanha promocional para a BYD, integrando 87 concessionárias em todo o país. Cada cliente que comprasse um carro da marca ganhava um número da sorte para concorrer a uma experiência exclusiva na UEFA Euro.
			
A campanha foi aprovada pela SPA em apenas 48 horas, enquanto uma landing page interativa foi desenvolvida para conectar e engajar os participantes.`,
			image : BYD,
		},
		{
			id : "corinthians",
			name : "Corinthians",
			title : "Gol de Sorte",
			description : `Desenvolvemos uma plataforma inovadora em apenas 48 horas para tornar a experiência de uma festa no Corinthians ainda mais especial. O objetivo era criar um sistema que integrasse um mecanismo de sorteios e um dashboard gerenciável, permitindo que os organizadores acompanhassem tudo em tempo real.
			
No dia do evento, os participantes faziam o check-in diretamente no local, garantindo sua validação para o sorteio.`,
			image : Corinthians,
		},
		{
			id : "engemon",
			name : "Engemon",
			title : "Construindo um futuro",
			description : `Utilizamos nosso produto Vollu para inovar na área de RH da Engemon. O Vollu foi utilizado para promover a evolução pessoal dos colaboradores por meio de testes interativos. Os participantes exploraram temas como autoconhecimento, habilidades profissionais e sustentabilidade. A iniciativa destacou o compromisso da Engemon com o desenvolvimento humano e a inovação dentro da empresa`,
			image : Engemon,
		},
		{
			id : "studiow",
			name : "Studio W",
			title : "Beleza com recompensa",
			description : `Fizemos uma parceria com o Studio W, renomado salão de beleza, para criar a campanha que engajou clientes por meio de experiências exclusivas e prêmios. A cada serviço realizado, os participantes concorriam ao Tratamento Taylor Made e uma experiência imersiva de beleza, em uma ação impulsionada por tecnologia e inteligência artificial.`,
			image : StudioW,
		},
		{
			id : "santomercado",
			name : "Santo Mercado",
			title : "Sabores da tecnologia",
			description : `O case feito para o Santo Mercado, anteriormente conhecido como Mercado Municipal de Santo Amaro, uniu muita tecnologia e recursos exclusivos disponibilizados pela Dompa. Criamos um hotsite interativo que permitiu que visitantes que realizassem compras acima de um valor definido concorressem a prêmios como cashback, vouchers e experiências VIP. 
			
Além disso, com um CRM personalizado, os dados foram gerenciados e segmentados, garantindo eficiência e bons resultados para o cliente.`,
			image : SantoMercado,
		},
	]
};
