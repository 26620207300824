const _ = require('lodash')

//import states directly

export const defaultApp = {
	info : "",
}

export const app = _.cloneDeep(defaultApp)

