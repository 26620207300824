import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/services/e-commerce.jpg"
import SC from "../../../assets/images/static/services/sucess-cases/ultra-crm.png"

export const dataECommerce =
	{
		id : "e-commerce",
		mainTitle : `Plataforma\nE-commerce`,
		mainDescription : `Simplifique Suas Vendas e Fidelize Clientes.\nUma solução completa para campanhas promocionais e vendas recorrentes.`,
		title : `Pronta para\nConverter`,
		description : <>A <strong>Plataforma de E-commerce</strong> da Dompa oferece ferramentas completas para criar, gerenciar e otimizar lojas online.<br/>Ideal para campanhas promocionais, nossa solução facilita vendas recorrentes, melhora a gestão de pedidos e entrega uma experiência diferenciada aos consumidores.</>,
		image : IMG,
		functionsSubTitle : `O Que Nossa Plataforma Pode Fazer?`,
		functions : [
			{
				title : `Gestão de Pedidos`,
				description : `Monitore e organize pedidos em tempo real com eficiência.`,
			},
			{
				title : `Venda Recorrente`,
				description : `Configure assinaturas e planos de recompra automática`,
			},
			{
				title : `Personalização de Lojas`,
				description : `Crie experiências únicas alinhadas à identidade da marca.`,
			},
			{
				title : `Integração de Pagamentos`,
				description : `Suporte para diversos métodos de pagamento, incluindo Pix, cartão de crédito e carteiras digitais`,
			},
			{
				title : `Análise de Desempenho`,
				description : `Dashboards interativos que mostram conversões, tráfego e ROI`,
			},
		],
		benefitsSubTitle : `Por Que Escolher Nossa Plataforma de E-commerce?`,
		benefits : [
			{
				title : `Aumento nas\nConversões`,
				description : `Interface otimizada\npara incentivar compras`,
			},
			{
				title : `Fidelização\ndo Cliente`,
				description : `Funcionalidades de venda recorrente\nque garantem retenção`,
			},
			{
				title : `Gestão\nSimplificada`,
				description : `Ferramentas intuitivas\npara gerenciar lojas e pedidos`,
			},
			{
				title : `Personalização\nCompleta`,
				description : `Total controle sobre\no design e funcionalidades da loja`,
			},
			{
				title : `Resultados\nMensuráveis`,
				description : `Relatórios detalhados para\nacompanhamento de KPIs`,
			},
		],
		applicationDescription:`Como Nossa Plataforma Transforma Negócios?`,
		applications : [
			{
				title : `Campanhas Promocionais`,
				description : `Criação de lojas exclusivas para sorteios, vale-brindes e promoções.`,
			},
			{
				title : `Assinaturas e Planos Recorrentes`,
				description : `Gestão de produtos que exigem recompra periódica.`,
			},
			{
				title : `Personalização de Experiência`,
				description : `Lojas online adaptadas para atender diferentes nichos de mercado.`,
			},
			{
				title : `Integração com Ferramentas de Marketing`,
				description : `Capacidade de conectar a plataforma a CRMs e campanhas de anúncios digitais.`,
			},
		],
		successCase : {
			name : `Faz Lá`,
			title : `E-commerce em Ação`,
			image : SC,
			solution : `Desenvolvimento de uma loja online\ncom integração de pagamentos e gestão\nautomatizada de clientes.`,
			challenge : `Criar uma plataforma\npromocional para venda de\nprodutos digitais e gestão de\nnúmeros da sorte para\nsorteios.`,
			percent : 30,
			percentExplanation : `Adesão dos Participantes`,
		},
	}



