import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/clients/engemon.jpg"

export const dataClientsEngemon =
	{
		id : "engemon",
		name : `Engemon`,
		challenge : `Inovar na área de RH da Engemon, promovendo o desenvolvimento pessoal e profissional dos colaboradores e engajar os participantes em temas relevantes como autoconhecimento, habilidades profissionais e sustentabilidade.`,
		image : IMG,
		solution : `Confira os resultados das nossas campanhas e veja como transformamos ideias em ações que geram engajamento e impacto real.`,
		solutionItems : [
			{
				description : `Utilização do Vollu para criar testes que estimulassem a evolução pessoal`,
			},
			{
				description : `Utilização do Vollu para criar testes que estimulassem a evolução pessoal`,
			},
		],
		results : `Engajamento elevado dos colaboradores, com participação ativa nos testes e fortalecimento do compromisso da Engemon com inovação e desenvolvimento humano dentro do ambiente corporativo.`,
		website : `https://engemon.com.br/`,
	}



