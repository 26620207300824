import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./history.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"
import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import {ReactComponent as IconBook} from "../../../../assets/images/icons/book.svg"
import Button from "../../layout/Button/Button";

const History = (
	{
		...props
	}
) => {

	const navigate = useNavigate()

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative z-5 pt-5" style={{marginTop:"-8rem"}}>
			<div className="history d-flex flex-row debug1 block-content ">
				<div className="flex-grow-1 flex-fill d-flex flex-column debug3">
					<div className="debug2">
						<IconBook width={72} height={72}/>
					</div>
					<div className="title debug2 text-96 font-tt">
						Histórico
					</div>
					<div className="description debug2 text-28 ">
						A Dompa nasceu em 2020, com o espírito inovador de uma startup determinada a transformar o mercado de campanhas promocionais no Brasil, um setor gigante e culturalmente forte, mas carente de inovação e marcado pela burocracia. Fundada e construída por pessoas apaixonados por tecnologia e marketing, a Dompa foi criada para atender à crescente demanda por soluções completas, seguras e tecnológicas, redefinindo os padrões do setor.
					</div>
					<div className="actions debug2 fw-medium">
						<Button
							template={"link-underline"}
							onClick={() => navigate('/quemsomos')}
							size={"medium"}
							label={"Saiba Mais"}
							IconLeft={IconNewWindow}
							iconHeight={14}
							iconWidth={16}
						/>

					</div>
				</div>
			</div>
		</div>
	)
}




export default History;
