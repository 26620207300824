import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./service-description.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import {ReactComponent as IconScreen} from "../../../../assets/images/icons/screen.svg"
import nl2br from "react-nl2br";
import {ReactComponent as CirclesImage} from "../../../../assets/images/circles.svg"
import Circles from "../../../components/blocks/Circles";

const ServiceDescription = (
	{
		data,
		...props
	}
) => {

	const navigate = useNavigate()

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative z-5 pt-5" style={{marginTop:"-8rem"}}>
			<div className="service-description d-flex flex-column debug1 block-content ">
				<div className="d-flex flex-row ">

					<div className="flex-grow-1 flex-fill d-flex flex-column debug3 ">
						<div className="debug2 icon-container">
							<IconScreen width={72} height={72}/>
						</div>
						<div className="title debug2 text-128 font-tt">
							{nl2br(data.title)}
						</div>
						<div className="description debug2 text-28 ">
							{nl2br(data.description)}
						</div>
					</div>

					<div
						className="image-container flex-grow-0 d-flex flex-column debug4 debug3 position-relative"
					>
						<Circles
							className="z-0"
							Image={CirclesImage}
							width={606}
							height={606}
							top={-403}
						/>
						<div className="image boxed position-absolute z-2 debug3"
							 style={{backgroundImage:`url(${data.image})`}}
						/>
						{/*<div className="position-absolute boxed sub-gradient z-1"/>*/}
					</div>
				</div>
			</div>
		</div>
	)
}




export default ServiceDescription;
