import React, {useEffect, useState,} from "react"

const InputTitle = (
	{
		form,
		field,
		required,
		name,
		id,
		placeHolder,
		feedback = "Campo inválido",
		className='',
		...props
	}
) => {

	return <div className="form-input-title fsp-16 fw-medium text-black-50">
		{field.label}
	</div>

}

export default InputTitle

