import {expandOptions} from "./staticLists";

const loadedOptions = {}

const remoteLists = {
	// FocusStyle : {
	// 	apiClass : aiApi,
	// 	url : 'api/job/list/focusStyles',
	// 	dataId : 'name',
	// 	dataCaption : "name",
	// 	expandOptions : {type:"focus-style-id", groupLabel:"Estilo"},
	// },
}

export const getRemoteList = (listId) => {
	return (remoteLists[listId] && loadedOptions[listId]) || null
}

export const deleteRemoteList = (listId) => {
	delete loadedOptions[listId]
}

export const deleteAllRemoteLists = () => {
	for (const listId in loadedOptions)
		delete loadedOptions[listId]
}

export const loadOptions = (listId) => {
	const listConfig = remoteLists[listId]

	if (!listConfig) {
		console.log("List Id not registered: ", listId)
		return Promise.resolve([]);
	}

	if (loadedOptions[listId] && listConfig.cache!==false) {
		//console.log('FROM CACHE', listId)
		return Promise.resolve(loadedOptions[listId]);
	}

	// loadedOptions[listId] = []

	//console.log("LOADING", listId)
	return (listConfig.apiClass )
		.post(listConfig.url, listConfig?.postData)
		.then(async (response) => {

			const reducer = listConfig.reducer || function(map, obj) {
				map.push({
					...obj,
					...{
						id:obj[listConfig.dataId],
						caption:
							typeof listConfig.dataCaption === 'function'
							? listConfig.dataCaption(obj)
							: obj[listConfig.dataCaption],
						image : typeof listConfig.dataImage === 'function'
							? listConfig.dataImage(obj)
							: obj[listConfig.dataImage] || obj.image,
						description : typeof listConfig.dataDescription === 'function'
							? listConfig.dataDescription(obj)
							: obj[listConfig.dataDescription] || obj.description,
					}
				})
				return map;
			}
			let result = response.data.reduce(reducer, [])
			if (listConfig.expandOptions) {
				result = expandOptions(result, listConfig.expandOptions)
			}

			if (listConfig.onLoadCallback)
				listConfig.onLoadCallback(result)

			loadedOptions[listId] = result;

			// console.log("LOADED", listId, result)
			return loadedOptions[listId]
		})
		.catch(async (error) => {
			console.log('Error loading list ', {listId, error})
			return []
		});
}

export const loadAllRemoteLists = async () => {
	console.log('Loading all remote lists...')
	const promises = []
	for (const listId in remoteLists)
		promises.push(loadOptions(listId))

	return Promise.all(promises)
}

