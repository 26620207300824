import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as Circles} from "../../assets/images/circles-6.svg"
import BackgroundImage from "../../assets/images/backgrounds/clients.jpg"
import {ReactComponent as Pointer} from "../../assets/images/icons/pointer.svg"
import Hero from "../components/blocks/Hero/Hero";
import TalkToUs from "../components/blocks/TalkToUs/TalkToUs";
import Button from "../components/layout/Button/Button";
import Page from "../components/layout/Page/Page";
import History from "../components/blocks/History/History";
import OurMissionVision from "../components/blocks/OurMissionVision/OurMissionVision";
import OurValues from "../components/blocks/OurValues/OurValues";
import OurTeam from "../components/blocks/OurTeam/OurTeam";
import services from "../../services";
import ClientDescription from "./blocks/ClientDescription/ClientDescription";

const ClientsPage = (
	{
		...props
	}
) => {
	const params = useParams()
	const clientId = useMemo(() => params.clientId, [params])
	const data = useMemo(() => services.clients.get(clientId || 'bello-futvolei'), [clientId])

	const navigate = useNavigate()

	if (!data)
		return null

	return (
		<Page>
			<Hero
				preTitle={<div className="text-28 fw-bold">CONHEÇA MAIS SOBRE NOSSOS CLIENTES</div>}
				title={<div className="text-128 fw-bold font-tt">Clientes</div>}
				description={<div className="text-28">Construímos parcerias de sucesso com empresas que confiam em nossa expertise para alcançar ótimos resultados. Confira quem já transformou os desafios em conquistas conosco.</div>}
				BackgroundImage={BackgroundImage}
				backgroundPositionY="-18.5rem"
				backgroundPaddingBottom="8rem"

				paddingTop={"12rem"}
				circleTop={"22rem"}

				CirclesImage={Circles}
				circleRotate={0}
				circleRotateX={0}
				circleRotateY={0}
				circleTranslateX={"-240px"}

				// circleTranslateX={"-100px"}
				circleTranslateY={"-16rem"}

				actions={<>
					<Button label="Contato" size={"big"} onClick={() => navigate(`/#contato`)}/>
					<Button label="Nossos serviços" template={"link"} size={"big"} IconRight={Pointer}/>
				</>}
			/>

			<ClientDescription data={data} />
			{/*<TalkToUs />*/}
		</Page>
	)
}

export default ClientsPage;
