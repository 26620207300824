import React, {useState, useMemo, useEffect, useCallback} from "react"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Page = (
	{
		...props
	}
) => {

	return (
		<div className="page-base">
			<Header/>
			<div className="position-relative">
				{props.children}
			</div>
			<Footer/>
		</div>
	)
}




export default Page;
