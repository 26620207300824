import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/products/dompacorp.png"
import KIMG from "../../../assets/images/static/products/knowmore/dompacorp.png"

export const dataProductsDompaCorp =
	{
		id : "dompa-corp",
		name : "Dompa Corp",
		title : "Um universo tecnológico pronto para auxiliar sua empresa",
		description : `A Dompa Corp é referência em soluções tecnológicas para campanhas promocionais, oferecendo uma plataforma completa e integrada que transforma ideias em resultados. Utilizamos ferramentas avançadas, inteligência artificial e estratégias personalizadas para garantir o sucesso de cada ação.`,
		image : IMG,
		users : "23.456",
		influencers : "3.456",

		mainDescription : `Um universo tecnológico pronto para auxiliar sua empresa.`,

		partnerType : `Empresas`,

		knowMoreDescription : `A Dompa Corp é referência em soluções tecnológicas para campanhas promocionais, oferecendo uma plataforma completa e integrada que transforma ideias em resultados. Utilizamos ferramentas avançadas, inteligência artificial e estratégias personalizadas para garantir o sucesso de cada ação.`,

		knowMoreImage2 : KIMG,
		knowMoreTitle2 : `Conheça o DaaS`,
		knowMoreDescription2 : `Empresas não precisam se preocupar com transtornos ou burocracias. Cuidamos de todas as etapas do processo promocional, desde a criação e documentação até a gestão da vigência e prova social. Nossa plataforma DaaS permite acompanhar em tempo real a evolução da campanha, trazendo transparência e eficiência.`,

		knowMoreInfoDescription : `Enquanto você foca em vender, nós cuidamos de todo o resto!.`,
		website : `https://admin.dompa.com.br/`,
	}
