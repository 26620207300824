import React, {useState, useMemo, useEffect, useCallback} from "react"

import {ReactComponent as Circles} from "../../../../assets/images/circles.svg"
import "./hero.scss"

const Hero = (
	{
		preTitle,
		title,
		description,
		BackgroundImage,
		backgroundPositionX = "center",
		backgroundPositionY = "top",
		backgroundPaddingBottom = 0,

		CirclesImage,
		circleRotate=45,
		circleRotateX=0,
		circleRotateY=0,
		circleTranslateX=0,
		circleTranslateY=0,
		paddingTop="8rem",
		circleTop="25%",
		actions,
	}
) => {

	return <div className="hero debug1 d-flex flex-row justify-content-center position-relative pb-4">
		<div className="d-flex flex-row block-content " style={{paddingBottom:backgroundPaddingBottom}}>

			<div className="position-absolute boxed background-image z-n1"
				style={{
					backgroundImage:`url(${BackgroundImage})`,
					backgroundPositionX,
					backgroundPositionY,
				}}
			/>


			<div className="position-absolute boxed bg-gradient-1 z-n1"/>
			<div className="position-absolute boxed bg-gradient-2 z-n1"/>

			<div className="flex-grow-1 flex-fill d-flex flex-column z-3 debug-1 " style={{paddingTop}}>
				{preTitle && <div className="pre-title debut2">{preTitle}</div>}
				<div className="title debug2">{title}</div>
				<div className="description debug2">{description}</div>
				{actions && <div className="actions debug2">{actions}</div>}
			</div>


			{CirclesImage &&
			<div className="position-absolute boxed w-100 z-2 debug4 overflow-x-hidden no-pointer-event" style={{height:"200%"}}
			>
				<div className="position-absolute"
					 style={{
						width:606, height:606, right:-606/2, top:circleTop,
						transform:`translateX(${circleTranslateX}) translateY(${circleTranslateY})`
					 }}
				>
					<CirclesImage
						width={"auto'"}
						height={"100%"}
						style={{
							transform:`rotateX(${circleRotateX}deg) rotateY(${circleRotateY}deg) rotate(${circleRotate}deg)`,
							transformOrigin:"center",
						}}
					/>
				</div>
			</div>
			}
		</div>
	</div>
}




export default Hero;
