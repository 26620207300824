import React, {useState, useMemo, useEffect, useCallback} from "react"

// import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
// import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import "./service-benefits.scss"
import  Bene1 from "../../../../assets/images/static/benefits/b1.jpg"
import Bene2 from "../../../../assets/images/static/benefits/b2.jpg"
import Bene3 from "../../../../assets/images/static/benefits/b3.jpg"
import Bene4 from "../../../../assets/images/static/benefits/b4.jpg"
import Bene5 from "../../../../assets/images/static/benefits/b5.jpg"
import {ReactComponent as IconScreen} from "../../../../assets/images/icons/screen.svg"

import nl2br from "react-nl2br";
import Circles from "../../../components/blocks/Circles";
import {ReactComponent as CirclesImage} from "../../../../assets/images/circles-5.svg";

const BenefitImages = [
	Bene1,
	Bene2,
	Bene3,
	Bene4,
	Bene5,
]


const Card = (
	{
		Image,
		title,
		description,
		counter,
		onClick,
		command,
		image,
		active,
		descriptionPaddingRight,
		actions,
	}
) => {
	return (
		<div
			className={`benefits-card d-flex flex-column position-relative debug5 ${active ? "active" : "inactive"}`}
			onClick={onClick}
		>
			<div className="position-absolute w-100 h-100 card-image debug3 z-n1 no-pointer-event" style={{backgroundImage:`url(${Image})`}} />
			<div className="position-absolute w-100 h-100 bg-gradient debug3 z-0 no-pointer-event" />

			<div className="icon-container flex-grow-0 debug1 z-1 d-flex align-items-center w-100 gap-4">
				<div className="card-icon"><IconScreen width={44} height={45} /></div>
				<div className="card-counter font-tt">{counter}</div>
			</div>

			<div className="card-title flex-grow-0 debug2 z-1 font-tt">{nl2br(title)}</div>
			<div className="card-description flex-grow-0 debug2 z-1" style={{paddingRight:descriptionPaddingRight}} >{nl2br(description)}</div>
		</div>
	)
}

const ServiceBenefits = (
	{
		data,
		actions,
	}
) => {

	const cards = data.benefits

	const [activeIndex, setActiveIndex] = useState(0)

	return <div className="service-benefits debug1 d-flex flex-row justify-content-center position-relative">
		<div className="d-flex flex-column block-content ">

			{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
			{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

			<div className="d-flex flex-column content z-2">
				<div className="flex-grow-0 debug2 title font-tt">
					Benefícios
				</div>
				<div className="flex-grow-0 debug2 sub-title">
					{data.benefitsSubTitle}
				</div>
				<div className="cards d-flex flex-row">
					{cards && cards.map((card, index) =>
						<Card
							onClick={() => setActiveIndex(index)}
							key={index}
							Image={BenefitImages[index]}
							counter={index+1}
							active={activeIndex===index}
							{...card}
						/>
					)}
				</div>
			</div>


		</div>
	</div>
}




export default ServiceBenefits;
