import * as app from "./app";
import * as cases from "./cases";
import * as products from "./products";
import * as allServices from "./services";
import * as clients from "./clients";

const services = {
  app,
  cases,
  products,
  services : allServices,
  clients,
};

export default services;
