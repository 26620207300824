import React, {useState, useMemo, useEffect, useCallback} from "react"
import {dataClientsBello} from "./data/bello-futvolei";
import {dataClientsBYD} from "./data/byd";
import {dataClientsCorinthians} from "./data/corinthians";
import {dataClientsEngemon} from "./data/engemon";
import {dataClientsSantoMercado} from "./data/santomercado";
import {dataClientsStudioW} from "./data/studiow";


export const list = () => {
	return [
		dataClientsBello,
		dataClientsBYD,
		dataClientsCorinthians,
		dataClientsEngemon,
		dataClientsSantoMercado,
		dataClientsStudioW,
	]
};

export const get = (id) => {
	return list().find(el => el.id===id)
};
