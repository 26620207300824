import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./cases.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
// import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import services from "../../../../services";

import nl2br from "react-nl2br"
import Button from "../../layout/Button/Button";

const Cases = (
	{
		...props
	}
) => {

	const [caseId, setCaseId] = useState("bello-futvolei")

	const cases = useMemo(() => services.cases.list(), [])
	const selectedCase = useMemo(() => cases.find(c => c.id===caseId), [caseId, cases])

	const navigate = useNavigate()
	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative mt-5" id="cases">


			<div className="cases d-flex flex-column block-content">
				<div className="main-title">Cases</div>
				<div className="menu d-flex flex-row justify-content-center text-18 py-4">
					{cases?.length && cases.map(item =>
						<div key={item.name} onClick={() => setCaseId(item.id)} className={`item ${caseId===item.id ? "active" : "inactive"}`}>{item.name}</div>
					)}
				</div>
				<div className="d-flex flex-row debug1  ">

					<div className="image position-relative" style={{backgroundImage:`url(${selectedCase.image})`}}>


					</div>
					<div className="middle-bar flex-grow-0">

					</div>
					<div className="info-container d-flex flex-column debug3">
						<div className="title debug2 text-18 fw-medium">
							Conheça o Case
						</div>
						<div className="title debug2 text-60">
							{selectedCase.title}
						</div>
						<div className="description debug2 text-28 ">
							{nl2br(selectedCase.description)}
						</div>
						<div className="actions debug2 d-flex flex-grow-1 flex-fill justify-content-end align-items-end">
							<div className="debug2 fw-medium justify-content-end ">
								<Button
									template={"link-underline"}
									size={"medium"}
									label={"Saiba Mais"}
									onClick={() => navigate(`/clientes/${selectedCase.id}`)}
									IconLeft={IconNewWindow}
									iconHeight={14}
									iconWidth={16}
								/>
							</div>

						</div>
					</div>
				</div>

			</div>


		</div>
	)
}




export default Cases;
