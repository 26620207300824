import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as Circles} from "../../assets/images/circles-2.svg"
import BackgroundImage from "../../assets/images/backgrounds/home.jpg"
import {ReactComponent as Pointer} from "../../assets/images/icons/pointer.svg"
import Hero from "../components/blocks/Hero/Hero";
import OurClients from "../components/blocks/OurClients/OurClients";
import WhoWeAre from "../components/blocks/WhoWeAre/WhoWeAre";
import OurServices from "../components/blocks/OurServices/OurServices";
import DompaMedia from "../components/blocks/DompaMedia/DompaMedia";
import Cases from "../components/blocks/Cases/Cases";
import Products from "../components/blocks/Products/Products";
import TalkToUs from "../components/blocks/TalkToUs/TalkToUs";
import Button from "../components/layout/Button/Button";
import Page from "../components/layout/Page/Page";

const HomePage = (
	{
		...props
	}
) => {
	const navigate = useNavigate()
	return (
		<Page>
			<Hero
				title={<div className="text-96 fw-bold">Revolucionando o mercado com soluções inteligentes</div>}
				description={<div className="text-28">Lideramos a inovação em campanhas promocionais com uma plataforma que utiliza inteligência artificial e tecnologia de ponta para criar experiências únicas e impulsionar marcas.</div>}
				BackgroundImage={BackgroundImage}

				paddingTop={"22rem"}
				circleTop={"22rem"}

				CirclesImage={Circles}
				circleRotate={0}
				circleRotateX={0}
				circleRotateY={0}
				circleTranslateX={"-160px"}

				actions={<>
					<Button label="Contato" size={"big"} onClick={() => navigate(`/#contato`)}/>
					<Button label="Nossos serviços" onClick={() => navigate(`/#servicos`)} template={"link"} size={"big"} IconRight={Pointer}/>
				</>}
				// circleTranslateX={"-100px"}
				circleTranslateY={"-100px"}
			/>
			<OurClients />
			<WhoWeAre/>
			<OurServices/>
			<DompaMedia/>
			<Cases/>
			<Products/>
			<TalkToUs/>
		</Page>
	)
}

export default HomePage;
