import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter} from "react-router-dom"
// import { HashRouter } from "react-router-dom";
// import { MemoryRouter } from "react-router-dom";

import App from "./App";
// import reportWebVitals from './reportWebVitals';

import { createOvermind } from "overmind";
import { Provider as OvermindProvider } from "overmind-react";
import { config } from "./overmind/index";

const overmind = createOvermind(config, { devtools: false });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.Fragment>
		<OvermindProvider value={overmind}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</OvermindProvider>
	</React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
