import React, {useState, useRef} from "react"

import InputMask from "react-input-mask";
import {onlyNumbers} from "../../../../helpers/utils";
import {Label} from "reactstrap";

const InputCpf = (
	{
		form,
		field,
		required,
		name,
		enabled,
		id,
		placeHolder,
		feedback = "Campo inválido",
		className='w-100',
		...props
	}
) => {
	// let inputRef = useRef()
	const value = form?.values ? form?.values[name] : null

    const handleChange = async e => {
        const value = e.target.value
		await form.setFieldValue(name, onlyNumbers(value))

        if (props.onChange) {
            props.onChange({value, form})
		}
    }

    return (
    	<>
			<InputMask
				// ref={inputRef}
				className={className}
				mask="999.999.999.99"
				maskChar=""
				placeholder={placeHolder}
				name={name}
				id={id}

				autoComplete="disabled"
				type="text"
				inputMode="decimal"
				onChange={handleChange}
				onFocus={props.onFocus}
				value={value}
				disabled={!enabled}

			/>
			<Label for={id} >
				{field.label}
			</Label>
        </>
    )
}

export default InputCpf;
