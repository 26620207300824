import { merge, namespaced } from 'overmind/config'
import { state } from './state'

import {
	createStateHook,
	createActionsHook,
	createEffectsHook,
	createReactionHook,
} from 'overmind-react'

import * as app from './app'

export const config = merge(
	{
		state
	},
	namespaced({
		app,
	})
)

export const useAppState = createStateHook()
export const useActions = createActionsHook()
export const useEffects = createEffectsHook()
export const useReaction = createReactionHook()


export function useOvermind (namespace) {
	return {
		state     : namespace ? useAppState()[namespace] : useAppState(),
		actions   : namespace ? useActions()[namespace] : useActions(),
		effects   : namespace ? useEffects()[namespace] : useEffects(),
		reactions : namespace ? useReaction()[namespace] : useReaction(),
	}
}



