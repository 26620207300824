import React, {useEffect, useState, useCallback, useRef} from "react"
import {Label,Input} from "reactstrap";

const InputText = (
	{
		form,
		field,
		required,
		enabled,
		name,
		id,
		placeHolder,
		feedback = "Campo inválido",
		className='',
		...props
	}
) => {

    const handleChange = async e => {
  		const value = e.target.value
		await form.setFieldValue(name, value)

        if (props.onChange) {
            props.onChange({value, form, field})
		}

    }


	return <>
			<Input
				className={className}
				placeholder={placeHolder}
				name={name}
				id={id}
				value={form?.values ? form?.values[name] : null}
				onChange={handleChange}
				onBlur={form.handleBlur}
				onFocus={props.onFocus}
				disabled={!enabled}
			/>
			<Label for={id} >
				{field.label}
			</Label>
	</>

}

export default InputText

